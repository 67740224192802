import React, { Component } from 'react';
import { _fetch } from './_fetch';
import { Spinner } from 'reactstrap';
import authService from './api-authorization/AuthorizeService';
import Toast from './toast.js';
import TextInput from './TextInput';
import NavBox from './navbox';

export class Lender extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    async componentDidMount() {
        let id = sessionStorage.getItem("ID");
        //let userId = sessionStorage.getItem("USER_ID");

        if (id != null) {
            this.setState({ CreditApplicationId: id, ShowNavBox: true });
            this.getBanks(id);
        }
    }

    async getBanks(id) {
        var fetch = _fetch();
        fetch.get("/lender/getBanks")
            .then((data) => {
                this.setState({ banks: data },
                    async function () {
                        await this.loadPage(id)
                    });
            });
    }

    calculateTotals() {
        var s = this.state;

        if (s.PurchaseOrRefinance !== 'Refinance') {
            var purchasePrice = this.zeroOrValue(s.PurchasePrice);
            var salesTax = this.zeroOrValue(s.SalesTax);
            var total = purchasePrice + salesTax;
            var trade = this.zeroOrValue(s.TradeBoatEquity);
            var downPayment = this.zeroOrValue(s.CashDownPayment);
            var loanAmount = total - (trade + downPayment);

            var loanRaw = this.formatCurrency(loanAmount);

            if (loanRaw && loanRaw.startsWith('$') && !loanRaw.includes('.')) {
                loanRaw = loanRaw + '.00';
            }
            else if (loanRaw.startsWith('$') && loanRaw.includes('.')) {
                var spl = loanRaw.split(".");
                if (spl[1].length === 0) {
                    loanRaw = loanRaw + '00';
                }

                if (spl[1].length === 1) {
                    loanRaw = loanRaw + '0';
                }
            }

            var totalRaw = this.formatCurrency(total);

            if (totalRaw && totalRaw.startsWith('$') && !totalRaw.includes('.')) {
                totalRaw = totalRaw + '.00';
            }
            else if (totalRaw.startsWith('$') && totalRaw.includes('.')) {
                spl = totalRaw.split(".");
                if (spl[1].length === 0) {
                    totalRaw = totalRaw + '00';
                }

                if (spl[1].length === 1) {
                    totalRaw = totalRaw + '0';
                }
            }

            this.setState({ TotalCost: totalRaw, LoanAmount: loanRaw }, function () {
                this.calcFeeAmount();
            });
        }
        else {
            this.setState({ LoanAmount: this.formatCurrency(s.LoanAmount) });
        }
    }

    async loadPage(id) {
        try {
            this.setState({ loading: true });
            var fetch = _fetch();
            fetch.get("/lender/getlender?id=" + id)
                .then((data) => {
                    if (data) {
                        var _state = this.state;
                        for (const key in data) {
                            if (data[key] != null) {
                                var _key = key.charAt(0).toUpperCase() + key.slice(1);
                                var _value = data[key];
                                _state[_key] = _value;
                            }
                        }
                        this.setState(_state, function () {
                            this.calculateTotals();
                            this.setState({ loading: false });
                            this.calcFeeAmount();
                        });
                    }
                });
        }
        catch (err) {
            this.setState({ loading: false });
            console.log('loadPage - ' + err);
        }
    }

    zeroOrValue(value) {
        if (value == undefined || value == 0 || value == null) return 0;
        //if (isNaN(value)) {
        //    value = value.toString().substring(1, value.toString().lenth - 1);
        //}
        var _value = value?.toString().replace(/,/g, '').replace('$', '');

        if (_value.length > 0) {

            var result = (isNaN(_value) || _value == undefined) ? 0 : parseFloat(_value);
            return result;
        }
        else {
            return 0;
        }
    }

    formatCurrency(value) {
        if (value == undefined || value == 0) return "";
        var _values = value.toString().replace(/,/g, '').replace('$', '');

        var dec = "";
        var _value = "";

        var elements = _values.split('.');
        if (elements?.length == 1) {
            _value = _values.split('.')[0];
        }
        else if (elements?.length > 1) {
            _value = _values.split('.')[0];
            dec = "." + _values.split('.')[1].substring(0, 2);
        }
        else {
            _value = _values;
        }
        if (_value.length > 0) {
            return new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }).format(_value) + dec;
        }
    }

    save(showToast, url) {
        var data = this.state;

        this.setState({
            loading: true
        });

        var fetch = _fetch();

        fetch.post("/lender/savelender", data)
            .then((id) => {
                if (id) {
                    if (url != undefined) {
                        window.location.href = url;
                    }
                    else {
                        if (showToast === 1) {
                            this.setState({
                                Id: id,
                                loading: false,
                                toastMessageSuccess: 'Saved successfully',
                                showToastSuccess: true
                            });
                        }
                    }
                }
                else {
                    this.setState({
                        showToast: true,
                        toastMessage: 'There was an issue saving your information. Please try again',
                        loading: false
                    });
                }
            })
    }

    calcFeeAmount() {
        var _fee = 0;
        var _feeString = "";

        if (this.state.LoanAmount !== undefined && this.state.FeePercent !== undefined) {
            var loanAmount = this.state.LoanAmount.toString().replace(/,/g, '').replace('$', '');
            var feePercent = this.state.FeePercent.toString().replace(/,/g, '').replace('$', '');

            var fee = parseFloat(loanAmount) * parseFloat(feePercent / 100);
            var _fee = Math.ceil(fee * 100) / 100;
        }

        _feeString = this.formatCurrency(_fee);

        if (_feeString.startsWith('$') && !_feeString.includes('.')) {
            _feeString = _feeString + '.00';
        }
        else if (_feeString.startsWith('$') && _feeString.includes('.')) {
            var spl = _feeString.split(".");
            if (spl[1].length == 0) {
                _feeString = _feeString + '00';
            }

            if (spl[1].length == 1) {
                _feeString = _feeString + '0';
            }
        }

        this.setState({ FeeAmount: _fee === 0 ? "" : _feeString });
    }

    sqlToJsDate(sqlDate) {
        if (sqlDate == "" || sqlDate === undefined) return sqlDate;

        var dateParts = sqlDate.split("T");
        return dateParts[0];
    }

    render() {
        return (
            <div className="container-top">
                <NavBox
                    ShowNavBox={this.state.ShowNavBox}
                    ClientNumber={this.state.CreditApplicationId}
                    LastName={this.state.BorrowerLastName}
                    SaveOnClick={(e) => this.save(e)}
                />
                <Toast
                    toastText={this.state.toastMessage}
                    listItems={this.state.toastItems}
                    showToast={this.state.showToast}
                    onToastClose={() => this.setState({ showToast: false })}
                    autoHideToastDelay={8000}
                />
                <Toast
                    toastText={this.state.toastMessageSuccess}
                    showToast={this.state.showToastSuccess}
                    onToastClose={() => this.setState({ showToastSuccess: false })}
                    autoHideToastDelay={5000}
                    isSuccessToast={true}
                />
                <div className="container-for-fixed-header">
                    {this.state.loading === true ?
                        <div>
                            <Spinner color="#000" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                        </div>
                        : null
                    }
                    <table responsive cellPadding={3}>
                        <tbody>
                            <tr>
                                <td align="left" colSpan={2}>
                                    <label style={{ fontSize: '22px', fontWeight: '500', color: '#596db3' }}>Lender Information</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <select
                                        value={this.state.BankId}
                                        onChange={(e) => {
                                            this.setState({ BankId: e.target.value })
                                        }}
                                    >
                                        <option key={0} value={0}>Select a Bank Name</option>
                                        {this.state.banks?.map((item, idx) =>
                                            <option key={idx} value={item.id}>{item.name}</option>
                                        )}
                                    </select>
                                </td>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Bank Approval #"
                                        TextChanged={(e) => {
                                            this.setState({ BankApprovalNumber: e }, function () {

                                            });
                                        }}
                                        Value={this.state.BankApprovalNumber ?? ""}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Bank Rep Name"
                                        TextChanged={(e) => {
                                            this.setState({ BankRepName: e }, function () {

                                            });
                                        }}
                                        Value={this.state.BankRepName ?? ""}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table responsive cellPadding={3}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="number"
                                        Label="Fee %"
                                        TextChanged={(e) => {
                                            this.setState({ FeePercent: e }, function () {
                                                this.calcFeeAmount();
                                            });
                                        }}
                                        Value={this.state.FeePercent}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Fee $ Amount"
                                        TextChanged={(e) => {
                                            this.setState({
                                                FeeAmount: e
                                            });
                                        }}
                                        Value={this.formatCurrency(this.state.FeeAmount)}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="date"
                                        Label="Invoice Date"
                                        TextChanged={(e) => {
                                            this.setState({ InvoiceDate: e }, function () {

                                            });
                                        }}
                                        Value={this.sqlToJsDate(this.state.InvoiceDate)}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="number"
                                        Label="Term (months)"
                                        MaxTextLength={3}
                                        TextChanged={(e) => {
                                            this.setState({ Term: e }, function () {

                                            });
                                        }}
                                        Value={this.state.Term ?? ""}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="number"
                                        Label="Interest Rate"
                                        TextChanged={(e) => {
                                            this.setState({ InterestRate: e }, function () {

                                            });
                                        }}
                                        Value={this.state.InterestRate ?? ""}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Monthly Payment"
                                        TextChanged={(e) => {
                                            this.setState({ MonthlyPayment: e }, function () {

                                            });
                                        }}
                                        Value={this.formatCurrency(this.state.MonthlyPayment)}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="date"
                                        Label="Date of the Note"
                                        TextChanged={(e) => {
                                            this.setState({ DateOfTheNote: e }, function () {

                                            });
                                        }}
                                        Value={this.sqlToJsDate(this.state.DateOfTheNote)}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="date"
                                        Label="First Payment Due Date"
                                        TextChanged={(e) => {
                                            this.setState({ FirstPaymentDueDate: e }, function () {

                                            });
                                        }}
                                        Value={this.sqlToJsDate(this.state.FirstPaymentDueDate)}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="date"
                                        Label="Maturity Date"
                                        TextChanged={(e) => {
                                            this.setState({ MaturityDate: e }, function () {

                                            });
                                        }}
                                        Value={this.sqlToJsDate(this.state.MaturityDate)}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Loan Processing Fee"
                                        TextChanged={(e) => {
                                            this.setState({ LoanProcessingFee: e }, function () {

                                            });
                                        }}
                                        Value={this.formatCurrency(this.state.LoanProcessingFee)}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="FL Doc Stamp Fee"
                                        TextChanged={(e) => {
                                            this.setState({ FlDocStampFee: e }, function () {

                                            });
                                        }}
                                        Value={this.formatCurrency(this.state.FlDocStampFee)}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Purchase Price"
                                        TextChanged={(e) => {
                                            this.setState({ PurchasePrice: e }, function () {
                                                this.calculateTotals();
                                            });
                                        }}
                                        Value={this.formatCurrency(this.state.PurchasePrice)}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Sales Tax"
                                        TextChanged={(e) => {
                                            this.setState({ SalesTax: e }, function () {
                                                this.calculateTotals();
                                            });
                                        }}
                                        Value={this.formatCurrency(this.state.SalesTax)}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <hr />
                            <tr>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Total Cost"
                                        TextChanged={(e) => {
                                            this.setState({ TotalCost: e }, function () {
                                                this.calculateTotals();
                                            });
                                        }}
                                        Value={this.formatCurrency(this.state.TotalCost)}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Net Trade Equity"
                                        TextChanged={(e) => {
                                            this.setState({ TradeBoatEquity: e }, function () {
                                                this.calculateTotals();
                                            });
                                        }}
                                        Value={this.formatCurrency(this.state.TradeBoatEquity)}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Cash Down"
                                        TextChanged={(e) => {
                                            this.setState({ CashDownPayment: e }, function () {
                                                this.calculateTotals();
                                            });
                                        }}
                                        Value={this.formatCurrency(this.state.CashDownPayment)}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <hr />
                            <tr>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Loan Amount"
                                        TextChanged={(e) => {
                                            this.setState({ LoanAmount: e }, function () {
                                                //this.calculateTotals();
                                            });
                                        }}
                                        Value={this.formatCurrency(this.state.LoanAmount)}
                                        Style={{ width: '160px' }}
                                    />
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <table responsive>
                        <tbody>
                            <tr>
                                <td colSpan={3} style={{ fontSize: '15px', fontWeight: '500', paddingTop: '15px' }} align="left">
                                    Loan Disbursements
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table responsive cellPadding={5}>
                                        <tbody>
                                            <tr>
                                                <td>Source</td>
                                                <td>Payable To</td>
                                                <td>Amount</td>
                                                <td>Check</td>
                                                <td>Wire</td>
                                            </tr>
                                            <tr>
                                                <td valign="middle">Seller</td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.state.SellerPayableTo}
                                                        onChange={(e) => { this.setState({ SellerPayableTo: e.target.value }); }}
                                                    />
                                                </td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.formatCurrency(this.state.SellerAmount)}
                                                        onChange={(e) => { this.setState({ SellerAmount: e.target.value }); }}
                                                        onKeyPress={(event) => {
                                                            var alpha = /^\d*\.?\d*$/;
                                                            if (!alpha.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            else {
                                                                return true;
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            var val = this.state.SellerAmount;
                                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                                val = val + '.00';
                                                            }
                                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                                var spl = e.target.value.split(".");
                                                                if (spl[1].length == 0) {
                                                                    val = val + '00';
                                                                }

                                                                if (spl[1].length == 1) {
                                                                    val = val + '0';
                                                                }
                                                            }
                                                            this.setState({
                                                                SellerAmount: val
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td align="center" valign="middle">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.SellerCheck}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                SellerCheck: !this.state.SellerCheck,
                                                                SellerWire: this.state.SellerCheck
                                                            })
                                                        }}
                                                    />
                                                </td>
                                                <td align="center" valign="middle">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.SellerWire}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                SellerWire: !this.state.SellerWire,
                                                                SellerCheck: this.state.SellerWire
                                                            })
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="middle">Loan Payoff #1</td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.state.LoanPayoff1PayableTo}
                                                        onChange={(e) => { this.setState({ LoanPayoff1PayableTo: e.target.value }); }}
                                                    />
                                                </td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.formatCurrency(this.state.LoanPayoff1Amount)}
                                                        onChange={(e) => { this.setState({ LoanPayoff1Amount: e.target.value }); }}
                                                        onKeyPress={(event) => {
                                                            var alpha = /^\d*\.?\d*$/;
                                                            if (!alpha.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            else {
                                                                return true;
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            var val = this.state.LoanPayoff1Amount;
                                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                                val = val + '.00';
                                                            }
                                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                                var spl = e.target.value.split(".");
                                                                if (spl[1].length == 0) {
                                                                    val = val + '00';
                                                                }

                                                                if (spl[1].length == 1) {
                                                                    val = val + '0';
                                                                }
                                                            }
                                                            this.setState({
                                                                LoanPayoff1Amount: val
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td align="center" valign="middle">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.LoanPayoff1Check}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                LoanPayoff1Check: e.target.checked,
                                                                LoanPayoff1Wire: !e.target.checked
                                                            })
                                                        }}
                                                    />
                                                </td>
                                                <td align="center" valign="middle">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.LoanPayoff1Wire}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                LoanPayoff1Wire: e.target.checked,
                                                                LoanPayoff1Check: !e.target.checked
                                                            })
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="middle">Loan Payoff #2</td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.state.LoanPayoff2PayableTo}
                                                        onChange={(e) => { this.setState({ LoanPayoff2PayableTo: e.target.value }); }}
                                                    />
                                                </td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.formatCurrency(this.state.LoanPayoff2Amount)}
                                                        onChange={(e) => { this.setState({ LoanPayoff2Amount: e.target.value }); }}
                                                        onKeyPress={(event) => {
                                                            var alpha = /^\d*\.?\d*$/;
                                                            if (!alpha.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            else {
                                                                return true;
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            var val = this.state.LoanPayoff2Amount;
                                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                                val = val + '.00';
                                                            }
                                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                                var spl = e.target.value.split(".");
                                                                if (spl[1].length == 0) {
                                                                    val = val + '00';
                                                                }

                                                                if (spl[1].length == 1) {
                                                                    val = val + '0';
                                                                }
                                                            }
                                                            this.setState({
                                                                LoanPayoff2Amount: val
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td align="center" valign="middle">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.LoanPayoff2Check}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                LoanPayoff2Check: e.target.checked,
                                                                LoanPayoff2Wire: !e.target.checked
                                                            })
                                                        }}
                                                    />
                                                </td>
                                                <td align="center" valign="middle">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.LoanPayoff2Wire}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                LoanPayoff2Wire: e.target.checked,
                                                                LoanPayoff2Check: !e.target.checked
                                                            })
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="middle">Sales Tax</td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.state.SalesTaxPayableTo}
                                                        onChange={(e) => { this.setState({ SalesTaxPayableTo: e.target.value }); }}
                                                    />
                                                </td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.formatCurrency(this.state.SalesTaxAmount)}
                                                        onChange={(e) => { this.setState({ SalesTaxAmount: e.target.value }); }}
                                                        onKeyPress={(event) => {
                                                            var alpha = /^\d*\.?\d*$/;
                                                            if (!alpha.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            else {
                                                                return true;
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            var val = this.state.SalesTaxAmount;
                                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                                val = val + '.00';
                                                            }
                                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                                var spl = e.target.value.split(".");
                                                                if (spl[1].length == 0) {
                                                                    val = val + '00';
                                                                }

                                                                if (spl[1].length == 1) {
                                                                    val = val + '0';
                                                                }
                                                            }
                                                            this.setState({
                                                                SalesTaxAmount: val
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td align="center" valign="middle">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.SalesTaxCheck}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                SalesTaxCheck: e.target.checked,
                                                                SalesTaxWire: !e.target.checked
                                                            })
                                                        }}
                                                    />
                                                </td>
                                                <td align="center" valign="middle">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.SalesTaxWire}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                SalesTaxWire: e.target.checked,
                                                                SalesTaxCheck: !e.target.checked
                                                            })
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="middle">Dealer/Broker</td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.state.DealerBrokerPayableTo}
                                                        onChange={(e) => { this.setState({ DealerBrokerPayableTo: e.target.value }); }}
                                                    />
                                                </td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.formatCurrency(this.state.DealerBrokerAmount)}
                                                        onChange={(e) => { this.setState({ DealerBrokerAmount: e.target.value }); }}
                                                        onKeyPress={(event) => {
                                                            var alpha = /^\d*\.?\d*$/;
                                                            if (!alpha.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            else {
                                                                return true;
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            var val = this.state.DealerBrokerAmount;
                                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                                val = val + '.00';
                                                            }
                                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                                var spl = e.target.value.split(".");
                                                                if (spl[1].length == 0) {
                                                                    val = val + '00';
                                                                }

                                                                if (spl[1].length == 1) {
                                                                    val = val + '0';
                                                                }
                                                            }
                                                            this.setState({
                                                                DealerBrokerAmount: val
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td align="center" valign="middle">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.DealerBrokerCheck}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                DealerBrokerCheck: e.target.checked,
                                                                DealerBrokerWire: !e.target.checked
                                                            })
                                                        }}
                                                    />
                                                </td>
                                                <td align="center" valign="middle">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.DealerBrokerWire}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                DealerBrokerWire: e.target.checked,
                                                                DealerBrokerCheck: !e.target.checked
                                                            })
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="middle">Escrow</td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.state.EscrowPayableTo}
                                                        onChange={(e) => { this.setState({ EscrowPayableTo: e.target.value }); }}
                                                    />
                                                </td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.formatCurrency(this.state.EscrowAmount)}
                                                        onChange={(e) => { this.setState({ EscrowAmount: e.target.value }); }}
                                                        onKeyPress={(event) => {
                                                            var alpha = /^\d*\.?\d*$/;
                                                            if (!alpha.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            else {
                                                                return true;
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            var val = this.state.EscrowAmount;
                                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                                val = val + '.00';
                                                            }
                                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                                var spl = e.target.value.split(".");
                                                                if (spl[1].length == 0) {
                                                                    val = val + '00';
                                                                }

                                                                if (spl[1].length == 1) {
                                                                    val = val + '0';
                                                                }
                                                            }
                                                            this.setState({
                                                                EscrowAmount: val
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td align="center" valign="middle">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.EscrowCheck}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                EscrowCheck: e.target.checked,
                                                                EscrowWire: !e.target.checked
                                                            })
                                                        }}
                                                    />
                                                </td>
                                                <td align="center" valign="middle">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.EscrowWire}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                EscrowWire: e.target.checked,
                                                                EscrowCheck: !e.target.checked
                                                            })
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td valign="middle">Other</td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.state.Other1PayableTo}
                                                        onChange={(e) => { this.setState({ Other1PayableTo: e.target.value }); }}
                                                    />
                                                </td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.formatCurrency(this.state.Other1Amount)}
                                                        onChange={(e) => { this.setState({ Other1Amount: e.target.value }); }}
                                                        onKeyPress={(event) => {
                                                            var alpha = /^\d*\.?\d*$/;
                                                            if (!alpha.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            else {
                                                                return true;
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            var val = this.state.Other1Amount;
                                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                                val = val + '.00';
                                                            }
                                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                                var spl = e.target.value.split(".");
                                                                if (spl[1].length == 0) {
                                                                    val = val + '00';
                                                                }

                                                                if (spl[1].length == 1) {
                                                                    val = val + '0';
                                                                }
                                                            }
                                                            this.setState({
                                                                Other1Amount: val
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td align="center" valign="middle">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.Other1Check}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                Other1Check: e.target.checked,
                                                                Other1Wire: !e.target.checked
                                                            })
                                                        }}
                                                    />
                                                </td>
                                                <td align="center" valign="middle">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.Other1Wire}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                Other1Wire: e.target.checked,
                                                                Other1Check: !e.target.checked
                                                            })
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td align="right" valign="middle">Loan Amount</td>
                                                <td valign="middle">
                                                    <input
                                                        type="text"
                                                        value={this.formatCurrency(this.state.LoanAmount)}
                                                        onChange={(e) => { this.setState({ LoanAmount: e.target.value }); }}
                                                        onKeyPress={(event) => {
                                                            var alpha = /^\d*\.?\d*$/;
                                                            if (!alpha.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                            else {
                                                                return true;
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            var val = this.state.LoanAmount;
                                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                                val = val + '.00';
                                                            }
                                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                                var spl = e.target.value.split(".");
                                                                if (spl[1].length == 0) {
                                                                    val = val + '00';
                                                                }

                                                                if (spl[1].length == 1) {
                                                                    val = val + '0';
                                                                }
                                                            }
                                                            this.setState({
                                                                LoanAmount: val
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td align="right">
                                    <textarea
                                        placeholder={"Notes / Comments"}
                                        value={this.state.Notes}
                                        onChange={(e) => { this.setState({ Notes: e.target.value }); }}
                                        style={{ width: '300px', height: '280px', marginLeft: '15px', resize: 'none' }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="footer-center-content">
                    <table style={{ width: '100%', height: '100%' }}>
                        <tbody>
                            <tr>
                                <td align="center" valign="middle">
                                    <button
                                        className="creditAppNext"
                                        style={{ whiteSpace: 'nowrap' }}
                                        disabled={this.state.loading ? true : false}
                                        onClick={() => {
                                            this.save(1);
                                        }}>
                                        Save
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}