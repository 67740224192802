import React, { Component } from 'react';
import { Container, Row, Col, Input, Table, Button, Alert } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import authService from '../api-authorization/AuthorizeService';
import AdminFunctions from './AdminFunctions';
import { MultiSelect } from "react-multi-select-component";
import '../layout/site.css';

export class UserDetails extends Component {
    static displayName = UserDetails.name;

    constructor(props) {
        super(props);

        this.state = {
            user: {},
            userId: null,
            client: null,
            active: null,
            firstName: null,
            lastName: null,
            officePhone: null,
            ext: null,
            homePhone: null,
            mobilePhone: null,
            email: null,
            clientOptions: [],
            success: undefined,
            locationId: 0,
            roles: [],
            salesRepInitials: ""
        };

    }

    async componentDidMount() {
        const isAdmin = AdminFunctions.isAdminAsync();
        if (!isAdmin) {
            this.props.history.push('/accessdenied');
        }
        else {
            this.getLocationsDropdown();
            if (this.props.location.state !== undefined) {
                if (this.props.location.state.existingUser) {
                    const userId = this.props.location.state.userId;
                    this.setState({ userId: userId });

                    setTimeout(() => {
                        this.getUserDetails(userId);
                    }, 250);
                }
                else {
                    this.getUserDetails("");
                }
            }
            else {
                this.props.history.push('/admin/roles/create');
            }
        }
    }

    render() {
        return (
            <div>
                <Container className='container-main'>
                    <Row>
                        <Col>
                            <h3>User Details</h3>
                        </Col>
                    </Row>
                    <Row className='row-20'>
                        <Col xs='6'>
                            <select
                                value={this.state.locationId}
                                onChange={(e) => {
                                    this.setState({ locationId: e.target.value })
                                }}
                            >
                                <option key={0} value={0}>---Location---</option>
                                {this.state.locationOptions?.map((item, idx) =>
                                    <option key={idx} value={item.id}>{item.label}</option>
                                )}
                            </select>
                        </Col>
                        <Col xs='2' className='col-left-20'>
                            <Input
                                type='checkbox'
                                onChange={(e) => this.setState({ active: e.target.checked })}
                                checked={!!this.state.active}
                                name='active'
                            />
                            <label className='padding-10'>Active</label>
                        </Col>
                    </Row>

                    <Row className='row-10'>
                        <Col xs='6'>
                            <Input
                                type='text'
                                value={this.state.firstName}
                                onChange={(e) => this.setState({ firstName: e.target.value })}
                                name='firstName'
                                id='firstName'
                                placeholder='First Name'
                            />
                        </Col>
                    </Row>
                    <Row className='row-10'>
                        <Col xs='6'>
                            <Input
                                type='text'
                                value={this.state.lastName}
                                onChange={(e) => this.setState({ lastName: e.target.value })}
                                name='lastName'
                                id='lastName'
                                placeholder='Last Name'
                                onBlur={() => {
                                    if (this.state.firstName !== null && this.state.lastName !== null && this.state.salesRepInitials.length === 0) {
                                        if (this.state.firstName.length > 0 && this.state.lastName.length > 0) {
                                            var salesRepInit = this.state.firstName.substring(0, 1).toUpperCase() + this.state.lastName.substring(0, 3).toUpperCase();
                                            this.setState({ salesRepInitials: salesRepInit });
                                        }
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='row-10'>
                        <Col xs='4'>
                            <Input
                                type='tel'
                                value={this.state.officePhone}
                                onChange={(e) => this.setState({ officePhone: e.target.value })}
                                name='officePhone'
                                id='officePhone'
                                placeholder='Office Phone'
                            />
                        </Col>
                        <Col xs='2'>
                            <Input
                                type='text'
                                value={this.state.ext}
                                onChange={(e) => this.setState({ ext: e.target.value })}
                                name='ext'
                                id='ext'
                                placeholder='Ext.'
                            />
                        </Col>
                    </Row>
                    <Row className='row-10'>
                        <Col xs='4'>
                            <Input
                                type='tel'
                                value={this.state.homePhone}
                                onChange={(e) => this.setState({ homePhone: e.target.value })}
                                name='homePhone'
                                id='homePhone'
                                placeholder='Home Phone'
                            />
                        </Col>
                    </Row>
                    <Row className='row-10'>
                        <Col xs='4'>
                            <Input
                                type='tel'
                                value={this.state.mobilePhone}
                                onChange={(e) => this.setState({ mobilePhone: e.target.value })}
                                name='mobilePhone'
                                id='mobilePhone'
                                placeholder='Mobile Phone'
                            />
                        </Col>
                    </Row>
                    <Row className='row-10'>
                        <Col xs='6'>
                            <Input
                                type='email'
                                value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                name='email'
                                id='email'
                                placeholder='Email'
                            />
                        </Col>
                    </Row>
                    <Row className='row-10'>
                        <Col xs='6'>
                            <Input
                                type='text'
                                value={this.state.salesRepInitials.toUpperCase()}
                                onChange={(e) => this.setState({ salesRepInitials: e.target.value })}
                                name='salesRepInitials'
                                id='salesRepInitials'
                                placeholder='Sales Rep ID'
                                style={{ width: '100px' }}
                                maxLength={4}
                            />
                        </Col>
                    </Row>
                    <Row className='row-20'>
                        <table className={"userRoles"}>
                            <tr>
                                <th>Assigned</th>
                                <th>Role</th>
                            </tr>
                            {this.state.roles?.map((item, inx) =>
                                <tr key={inx}>
                                    <td><Input
                                        type="checkbox"
                                        onChange={(e) => {
                                            var _roles = this.state.roles;
                                            _roles[inx].assigned = e.target.checked;
                                            this.setState({ roles: _roles })
                                        }}
                                        checked={item.assigned} />
                                    </td>
                                    <td>{item.name}</td>
                                </tr>
                            )}
                        </table>
                    </Row>
                    <Row className='row-20'>
                        <Col xs='5'>
                            <Button
                                color='primary'
                                onClick={() => this.props.history.push('/admin/users')}
                            >
                                Back to Users
                            </Button>
                        </Col>
                        <Col xs='1'>
                            <Button
                                color='primary'
                                style={{ marginLeft: '50%', marginRight: '50%' }}
                                onClick={() => this.handleSave()}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                    <Row className='row-20'>
                        <Col>
                            {this.state.success === true && (
                                <Alert color='success'>
                                    {this.state.userId ? 'User updated successfully!' : 'User added successfully!'}
                                </Alert>
                            )}
                            {this.state.success === false && (
                                <Alert color='danger'>
                                    {this.state.userId ? 'Error updating User!' : 'Error adding User!'}
                                </Alert>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div >
        )
    }

    async getLocationsDropdown() {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/Locations/GetDropdown`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ locationOptions: data });
    }

    async getUserDetails(userId) {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/Admin/GetUserDetailsAsync?id=${userId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            officePhone: data.officePhone,
            ext: data.ext,
            homePhone: data.homePhone,
            mobilePhone: data.mobilePhone,
            active: data.active,
            locationId: data.locationId,
            roles: data.userRoles,
            salesRepInitials: data.salesRepInitials === null ? "" : data.salesRepInitials
        });
    }

    async handleSave() {
        const token = await authService.getAccessToken();
        const dto = {
            id: this.state.userId,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            officePhone: this.state.officePhone,
            ext: this.state.ext,
            homePhone: this.state.homePhone,
            mobilePhone: this.state.mobilePhone,
            active: this.state.active,
            locationId: this.state.locationId,
            UserRoles: this.state.roles,
            SalesRepInitials: this.state.salesRepInitials
        }
        if (this.state.userId) {
            await this.editUser(dto, token);
        }
        else {
            await this.createUser(dto, token);
        }
    }

    async editUser(dto, token) {
        const response = await fetch('api/Admin/EditUserAsync', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        this.setState({ success: data });
    }

    async createUser(dto, token) {
        const response = await fetch('api/Admin/CreateUser', {
            method: 'POST',
            body: JSON.stringify(dto),
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const data = await response.json();
        this.setState({ success: data });
    }
}