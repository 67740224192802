import React, { useEffect, useState, useRef } from "react";
import { _fetch } from './_fetch';
import authService from './api-authorization/AuthorizeService';
import Modal from 'react-modal';
import './navbox.css';

function NavBox({
    ShowNavBox,
    ClientNumber,
    LastName,
    SaveOnClick }) {

    const prevClientNumber = useRef();

    useEffect(() => {
        if (ClientNumber !== undefined && prevClientNumber.current !== ClientNumber) {
            setClientNumber(ClientNumber);
            prevClientNumber.current = ClientNumber;

            loadNavBoxDropdowns();
        }
        setClientLastName(LastName);
    }, [ShowNavBox, LastName]);

    const [CreditApplicationStatuses, setCreditApplicationStatuses] = useState([]);
    const [Locations, setLocations] = useState([]);
    const [SalesReps, setSalesReps] = useState([]);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [_ShowNavBox, setShowNavBox] = useState(false);
    const [CreditApplicationStatusId, setCreditApplicationStatusId] = useState(undefined);
    const [LocationId, setLocationId] = useState(undefined);
    const [SalesRepId, setSalesRepId] = useState(undefined);
    const [PrintLoanApplication, setPrintLoanApplication] = useState(false);
    const [PrintPFS, setPrintPFS] = useState(false);
    const [_LastName, setClientLastName] = useState("");
    const [ClientNo, setClientNumber] = useState(0);
    const [PFSAvailable, setPFSAvailable] = useState(false);

    async function loadNavBoxDropdowns() {
        getCreditApplicationStatuses();
        getLocations();
        getSalesReps();
        await loadApplicantData();
    }

    async function loadApplicantData() {
        var fetch = _fetch();
        fetch.get("/creditapplication/GetNavBoxData?id=" + ClientNumber)
            .then((data) => {
                if (data) {
                    setClientNumber(data.clientNumber);
                    setClientLastName(data.lastName == null ? _LastName : data.lastName);
                    setCreditApplicationStatusId(data.creditApplicationStatusId);
                    setLocationId(data.locationId);
                    setSalesRepId(data.salesRepId);
                    setShowNavBox(ShowNavBox);
                    hasPFS(ClientNumber);
                }
            });
    }

    function getCreditApplicationStatuses() {
        var fetch = _fetch();
        fetch.get("/creditapplication/GetCreditApplicationStatuses")
            .then((data) => {
                setCreditApplicationStatuses(data);
            })
    }

    function getLocations() {
        var fetch = _fetch();
        fetch.get("/creditapplication/GetLocations")
            .then((data) => {
                setLocations(data);
            })
    }

    function getSalesReps() {
        var fetch = _fetch();
        fetch.get("/creditapplication/GetSalesReps")
            .then((data) => {
                setSalesReps(data);
            })
    }

    function hasPFS(id) {
        var fetch = _fetch();
        fetch.get("/creditapplication/hasPFS?id=" + id)
            .then((data) => {
                setPFSAvailable(data);
            });
    }

    function OnClickOrChange(sender, value) {
        switch (sender) {
            case "Home":
                window.location.href = "/";
                break;
            case "PrintDocuments":
                setShowPrintModal(true);
                break;
            case "LoanStatus":
                if (value !== undefined) updateCreditApplicationStatus(value);
                break;
            case "Location":
                if (value !== undefined) updateLocation(value);
                break;
            case "SalesRep":
                if (value !== undefined) updateSalesRep(value);
                break;
            case "DocumentVault":
                window.location.href = "/DocumentVault";
                break;
            case "BorrowerInformation":
                window.location.href = "/CreditApplicationStep1";
                break;
            case "LenderInformation":
                window.location.href = "/Lender";
                break;
            case "VesselInformation":
                window.location.href = "/Vessel";
                break;
            case "SellerInformation":
                window.location.href = "/SellerInformation";
                break;
            case "Report":
                window.location.href = "/Reporting";
                break;
            case "PFS":
                window.location.href = "/PersonalFinancialStatement?id=" + ClientNumber;
                break;
            default:
        }
    }

    function updateCreditApplicationStatus(value) {
        var fetch = _fetch();

        var data = {
            Id1: parseInt(value),
            Id2: parseInt(ClientNo)
        }

        fetch.post("/creditapplication/UpdateCreditApplicationStatus", data)
            .then((result) => {
                setCreditApplicationStatusId(value);
            })
    }

    function updateLocation(value) {
        var fetch = _fetch();

        var data = {
            Id1: parseInt(value),
            Id2: parseInt(ClientNo)
        }

        fetch.post("/creditapplication/UpdateLocation", data)
            .then((result) => {
                setLocationId(value);
            })
    }

    function updateSalesRep(value) {
        var fetch = _fetch();

        var data = {
            Id1: parseInt(value),
            Id2: parseInt(ClientNo)
        }

        fetch.post("/creditapplication/UpdateSalesRep", data)
            .then((result) => {
                setSalesRepId(value);
            })
    }

    async function print() {
        const token = await authService.getAccessToken();
        if (PrintLoanApplication) {
            var tempfilename = "LoanApplication.pdf";
            var filename = "";

            var f = _fetch();
            await fetch("/Pdf/GenerateLoanApplicationPDF?id=" + ClientNo, {
                method: 'GET',
                body: null,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then((res) => {
                    const header = res.headers.get('Content-Disposition');
                    const parts = header.split(';');
                    if (parts[1]) {
                        filename = parts[1].split('=')[1];
                    }
                    else {
                        filename = tempfilename;
                    }
                    return res.blob();
                })
                .then(data => {
                    f.downloadFile(data, filename, "application/pdf");
                    //this.setState({ loading: false });
                })
                .catch((error) => {
                    console.log('error in print (Loan Application) - ' + error.message);
                    //this.setState({ loading: false });
                });
        }

        if (PrintPFS) {
            var filename = "LoanApplication.pdf";

            await fetch("/Pdf/GeneratePersonalFinancialStatementPDF?id=" + ClientNo, {
                method: 'GET',
                body: null,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then((res) => {
                    const header = res.headers.get('Content-Disposition');
                    const parts = header.split(';');
                    if (parts[1]) {
                        filename = parts[1].split('=')[1];
                    }

                    return res.blob();
                })
                .then(data => {
                    var f = _fetch();
                    f.downloadFile(data, filename, "application/pdf");
                    //this.setState({ loading: false });
                })
                .catch((error) => {
                    console.log('error in print (Loan Application) - ' + error.message);
                    //this.setState({ loading: false });
                });
        }
        setShowPrintModal(false);
        setPrintLoanApplication(false);
        setPrintPFS(false);
    }

    return (
        <div className="navbox" style={_ShowNavBox === true ? { opacity: '1' } : { opacity: '0' }} >
            <table className="navboxTable">
                <tbody>
                    <tr>
                        <td>
                            Client #
                        </td>
                        <td style={{ fontWeight: 'bold', fontSize: '1em', letterSpacing: '.009rem' }}>
                            {ClientNo === 0 ? "" : ClientNo}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Last Name
                        </td>
                        <td style={{ fontWeight: 'bold', fontSize: '1em', letterSpacing: '.009rem' }}>
                            {_LastName}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Loan Status
                        </td>
                        <td>
                            <select
                                disabled={ClientNo === undefined || ClientNo === 0}
                                value={CreditApplicationStatusId}
                                onChange={(e) => {
                                    if (e.target.value !== undefined) OnClickOrChange("LoanStatus", e.target.value);
                                }}
                            >
                                <option key={0} value={0}>---</option>
                                {CreditApplicationStatuses?.map((item, idx) =>
                                    <option key={idx} value={item.id}>{item.name}</option>
                                )}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Sales Rep
                        </td>
                        <td>
                            <select
                                disabled={ClientNo === undefined || ClientNo === 0}
                                value={SalesRepId}
                                onChange={(e) => {
                                    if (e.target.value !== undefined) OnClickOrChange("SalesRep", e.target.value);
                                }}
                            >
                                <option key={0} value={0}>---</option>
                                {SalesReps?.map((item, idx) =>
                                    <option key={idx} value={item.id}>{item.name}</option>
                                )}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Location
                        </td>
                        <td>
                            <select
                                disabled={ClientNo === undefined || ClientNo === 0}
                                value={LocationId}
                                onChange={(e) => {
                                    if (e.target.value !== undefined) OnClickOrChange("Location", e.target.value);
                                }}
                            >
                                <option key={0} value={0}>---</option>
                                {Locations?.map((item, idx) =>
                                    <option key={idx} value={item.id}>{item.name}</option>
                                )}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <div className="link"
                                onClick={(e) => {
                                    OnClickOrChange("Home", null);
                                    SaveOnClick("/");
                                }}
                            >Home
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <div className={ClientNo === undefined || ClientNo === 0 ? "link-disabled" : "link"}
                                onClick={(e) => {
                                    OnClickOrChange("BorrowerInformation", null);
                                    SaveOnClick("/CreditApplicationStep1");
                                }}
                            >Borrower Information
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <div className={!PFSAvailable ? "link-disabled" : "link"}
                                onClick={(e) => {
                                    OnClickOrChange("PFS", null);
                                    SaveOnClick("/PersonalFinancialStatement");
                                }}
                            >Personal Financial Statement
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <div className={ClientNo === undefined || ClientNo === 0 ? "link-disabled" : "link"}
                                onClick={(e) => {
                                    OnClickOrChange("LenderInformation", null);
                                    SaveOnClick("/Lender");
                                }}
                            >Lender Information
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <div className={ClientNo === undefined || ClientNo === 0 ? "link-disabled" : "link"}
                                onClick={(e) => {
                                    OnClickOrChange("VesselInformation", null);
                                    SaveOnClick("/Vessel");
                                }}
                            >Vessel Information
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <div className={ClientNo === undefined || ClientNo === 0 ? "link-disabled" : "link"}
                                onClick={(e) => {
                                    OnClickOrChange("SellerInformation", null);
                                    SaveOnClick("/SellerInformation");
                                }}
                            >Seller Information
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <div className={ClientNo === undefined || ClientNo === 0 ? "link-disabled" : "link"}
                                onClick={(e) => {
                                    OnClickOrChange("DocumentVault", null);
                                    SaveOnClick("/DocumentVault");
                                }}
                            >Document Vault
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <div className={ClientNo === undefined || ClientNo === 0 ? "link-disabled" : "link"}
                                onClick={(e) => {
                                    OnClickOrChange("Reports", null);
                                    SaveOnClick("/Reporting");
                                }}
                            >Reports
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <button
                                disabled={ClientNo === undefined || ClientNo === 0}
                                className="creditAppNextUpload"
                                style={{ whiteSpace: 'nowrap' }}
                                onClick={() => {
                                    OnClickOrChange("PrintDocuments", null);
                                }}
                            >Print Documents
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <button
                                disabled={ClientNo === undefined || ClientNo === 0}
                                className="creditAppNextUpload"
                                style={{ whiteSpace: 'nowrap' }}
                                onClick={() => {
                                    OnClickOrChange("DuplicateLoanApplication", null);
                                }}
                            >Duplicate Loan Application
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <Modal
                isOpen={showPrintModal}
                //onAfterOpen={ }
                onRequestClose={() => setShowPrintModal(false)}
                className="popup"
                contentLabel={""}
                overlayClassName="Overlay"
            >
                <div style={{ display: 'flex', justifyContent: 'center', padding: '3px' }}>
                    <table className="printTable">
                        <tbody>
                            <tr>
                                <td>
                                    <input type="checkbox" onChange={(e) => setPrintLoanApplication(e.target.checked)} />
                                </td>
                                <td>
                                    Loan Application
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type="checkbox" disabled={!PFSAvailable} onChange={(e) => setPrintPFS(e.target.checked)} />
                                </td>
                                <td>
                                    <label style={{ fontSize: '1.05em', opacity: PFSAvailable ? 1 : .5 }}>Personal Financial Statement</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type="checkbox" onChange={(e) => { }} />
                                </td>
                                <td>
                                    Approval Notice
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type="checkbox" onChange={(e) => { }} />
                                </td>
                                <td>
                                    Denial Notice
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type="checkbox" onChange={(e) => { }} />
                                </td>
                                <td>
                                    Origination Invoice
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type="checkbox" onChange={(e) => { }} />
                                </td>
                                <td>
                                    Loan Closing Worksheet
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <button
                                        className="printButton"
                                        onClick={async () => { await print(); }}
                                    >
                                        Print
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal>
        </div>
    );
};

export default NavBox;