import React, { Component } from 'react';
import { _fetch } from './_fetch';
import { Spinner } from 'reactstrap';
import TextInput from './TextInput';
import { customFunctions } from './CustomFunctions.js';
import Toast from './toast.js';

export class LoanApplicationStep1 extends Component {
    static displayName = LoanApplicationStep1.name;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showToast: false,
            toastMessage: '',
            toastItems: [],
            ContinueToken: ''
        }

        this.termOtherRef = React.createRef();
        this.purchasePriceRef = React.createRef();
        this.loanAmountRef = React.createRef();
    }

    componentDidMount() {
        let searchParams = new URLSearchParams(window.location.search);
        let salesRep = searchParams.get('sr');

        if (salesRep != null) {
            this.setState({ SalesRep: salesRep });
        }

        if (localStorage.getItem("CONTINUE_TOKEN") && localStorage.getItem("CONTINUE_TOKEN") !== null) {
            var id = localStorage.getItem("CONTINUE_TOKEN");
            this.setState({ ContinueToken: id });
            this.loadPage(id);
        }
    }

    calculateTotals() {
        var s = this.state;
        if (this.state.PurchaseOrRefinance !== 'Refinance') {
            var purchasePrice = this.zeroOrValue(s.PurchasePrice);
            var salesTax = this.zeroOrValue(s.SalesTax);
            var total = purchasePrice + salesTax;
            var trade = this.zeroOrValue(s.TradeBoatEquity);
            var downPayment = this.zeroOrValue(s.CashDownPayment);
            var loanAmount = total - (trade + downPayment);

            var loanRaw = this.formatCurrency(loanAmount);

            if (loanRaw && loanRaw.startsWith('$') && !loanRaw.includes('.')) {
                loanRaw = loanRaw + '.00';
            }
            else if (loanRaw.startsWith('$') && loanRaw.includes('.')) {
                var spl = loanRaw.split(".");
                if (spl[1].length === 0) {
                    loanRaw = loanRaw + '00';
                }

                if (spl[1].length === 1) {
                    loanRaw = loanRaw + '0';
                }
            }

            var totalRaw = this.formatCurrency(total);

            if (totalRaw && totalRaw.startsWith('$') && !totalRaw.includes('.')) {
                totalRaw = totalRaw + '.00';
            }
            else if (totalRaw.startsWith('$') && totalRaw.includes('.')) {
                spl = totalRaw.split(".");
                if (spl[1].length === 0) {
                    totalRaw = totalRaw + '00';
                }

                if (spl[1].length === 1) {
                    totalRaw = totalRaw + '0';
                }
            }

            this.setState({ TotalCost: totalRaw, LoanAmount: loanRaw });
        }
        else {
            this.setState({ LoanAmount: this.formatCurrency(s.LoanAmount) });
        }
    }

    zeroOrValue(value) {
        if (value == undefined || value == 0 || value == null) return 0;

        var _value = value?.toString().replace(/,/g, '').replace('$', '');

        if (_value.length > 0) {

            var result = (isNaN(_value) || _value == undefined) ? 0 : parseFloat(_value);
            return result;
        }
        else {
            return 0;
        }
    }

    formatCurrency(value) {
        if (value == undefined) return "";
        var _values = value.toString().replace(/,/g, '').replace('$', '');

        var dec = "";
        var _value = "";

        var elements = _values.split('.');
        if (elements?.length == 1) {
            _value = _values.split('.')[0];
        }
        else if (elements?.length > 1) {
            _value = _values.split('.')[0];
            dec = "." + _values.split('.')[1].substring(0, 2);
        }
        else {
            _value = _values;
        }
        if (_value.length > 0) {
            return new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }).format(_value) + dec;
        }
    }

    save() {
        try {
            var item = [];
            if (this.state.TakingTitle === undefined || this.state.TakingTitle?.length === 0) {
                item.push("What type of application is this?");
            }
            if (this.state.Ownership === undefined || this.state.Ownership?.length === 0) {
                item.push("How do you plan to take ownership?");
            }
            if (this.state.PurchaseOrRefinance === undefined || this.state.PurchaseOrRefinance?.length === 0) {
                item.push("Purchase or Refinance");
            }
            if (this.state.PurchaseOrRefinance !== 'Refinance' && (this.state.NewOrUsed === undefined || this.state.NewOrUsed?.length === 0)) {
                item.push("New or Used");
            }
            if (this.state.PurchaseOrRefinance === "Purchase" && this.state.DealerPrivate === undefined || this.state.DealerPrivate?.length === 0) {
                item.push("Dealer/Broker or Private Party");
            }
            if (this.state.DealerPrivate === "Dealer" && (this.state.DealerName === undefined || this.state.DealerName?.length === 0)) {
                item.push("Dealer/Broker Name");
            }
            if (this.state.PurchaseOrRefinance === "Purchase" && (this.state.PurchasePrice === undefined || this.state.PurchasePrice?.length === 0)) {
                item.push("Purchase Price");
            }
            if (this.state.PurchaseOrRefinance === 'Refinance' && (this.state.LoanAmount === undefined || this.state.LoanAmount?.length === 0)) {
                item.push("Loan Amount");
            }
            if (this.state.Term?.length === 0 || this.state.Term === undefined) {
                item.push("Term");
            }
            if (this.state.Term === 0 && (this.state.TermOther === undefined || this.state.TermOther?.length === 0)) {
                item.push("Term Other Value");
            }

            if (item.length == 0) {
                this.setState({ loading: true });
                var fetch = _fetch();

                fetch.post("/CreditApplicationPublic/SaveCreditApplicationStep1Public", this.state)
                    .then((id) => {
                        if (id) {
                            this.setState({ loading: false, LastStep: "1" });
                            localStorage.setItem("CONTINUE_TOKEN", id.continueToken);
                            window.location.href = '/LoanApplicationStep2';
                        }
                        else {
                            this.setState({
                                showToast: true,
                                toastMessage: 'There was an issue saving your information. Please try again',
                                loading: false
                            });
                        }
                    })
            }
            else {
                this.setState({
                    showToast: true,
                    toastMessage: 'The following fields are required:',
                    toastItems: item
                });
            }
        }
        catch (err) {
            this.setState({ loading: false });
            console.log('save - ' + err);
        }
    }

    loadPage(id) {
        try {
            this.setState({ loading: true });
            var fetch = _fetch();
            fetch.get("/CreditApplicationPublic/GetCreditApplicationStep1Public?id=" + id)
                .then((data) => {
                    if (data.continueToken?.length > 0) {
                        var _state = this.state;
                        for (const key in data) {
                            if (data[key] != null) {
                                var _key = key.charAt(0).toUpperCase() + key.slice(1);
                                var _value = data[key];
                                _state[_key] = _value?.toString();
                            }
                        }
                        this.setState(_state, function () {
                            this.calculateTotals();
                            localStorage.setItem("APP_TYPE", this.state.TakingTitle);
                            this.setState({ loading: false });
                        });
                    }
                    else {
                        this.setState({ loading: false });
                        localStorage.removeItem("CONTINUE_TOKEN");
                    }
                })
        }
        catch (err) {
            this.setState({ loading: false });
            console.log('loadPage - ' + err);
        }
    }

    render() {
        return (
            <div className="container-top">
                <Toast
                    toastText={this.state.toastMessage}
                    listItems={this.state.toastItems}
                    showToast={this.state.showToast}
                    onToastClose={() => this.setState({ showToast: false })}
                    autoHideToastDelay={10000}
                />
                <div className="container-for-fixed-header">
                    {this.state.loading === true ?
                        <div>
                            <Spinner color="#000" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                        </div>
                        : null
                    }
                    <table cellSpacing={2} cellPadding={2}>
                        <tbody>
                            <tr>
                                <td align="left" colSpan={2}>
                                    <label style={{ fontSize: '22px', fontWeight: '500', color: '#596db3' }}>Credit Application</label>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    How did you hear about us?
                                </td>
                                <td align="left">
                                    <input type="text" onChange={(e) => this.setState({ HearAboutUs: e.target.value })} value={this.state.HearAboutUs ?? ''}></input>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    What type of application is this?
                                </td>
                                <td align="left">
                                    <div className="radioGroup">
                                        <input type="radio"
                                            name="appType"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.TakingTitle === 'Individual'}
                                            onChange={(e) => {
                                                this.setState({ TakingTitle: e.target.checked ? "Individual" : this.state.TakingTitle });
                                                localStorage.setItem("APP_TYPE", "Individual");
                                            }}
                                            value={this.state.TakingTitle ?? false}>
                                        </input>
                                        <label style={{ marginRight: '10px', marginBottom: '0', verticalAlign: 'middle' }}>Individual</label>
                                        <input type="radio"
                                            name="appType"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.TakingTitle === 'Jointly'}
                                            onChange={(e) => {
                                                this.setState({ TakingTitle: e.target.checked ? "Jointly" : this.state.TakingTitle });
                                                localStorage.setItem("APP_TYPE", "Jointly");
                                            }}
                                            value={this.state.TakingTitle ?? false}>
                                        </input>
                                        <label style={{ marginBottom: '0', verticalAlign: 'middle' }}>Joint</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    How do you plan to take ownership?
                                </td>
                                <td align="left">
                                    <div className="radioGroup">
                                        <input type="radio"
                                            name="ownership"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.Ownership === 'Personally'}
                                            onChange={(e) => this.setState({ Ownership: e.target.checked ? "Personally" : this.state.Ownership })}
                                            value={this.state.Ownership ?? false}>
                                        </input>
                                        <label>Personally</label>
                                        <input type="radio"
                                            name="ownership"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.Ownership === 'LLC'}
                                            onChange={(e) => this.setState({ Ownership: e.target.checked ? "LLC" : this.state.Ownership })}
                                            value={this.state.Ownership ?? false}>
                                        </input>
                                        <label>LLC</label>
                                        <input type="radio"
                                            name="ownership"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.Ownership === 'Trust'}
                                            onChange={(e) => this.setState({ Ownership: e.target.checked ? "Trust" : this.state.Ownership })}
                                            value={this.state.Ownership ?? false}>
                                        </input>
                                        <label>Trust</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    Will this vessel be used as a<br />residence / primary dwelling?
                                </td>
                                <td align="left">
                                    <div className="radioGroup">
                                        <input type="radio"
                                            name="dwelling"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.ResidentPrimaryDwelling === 'Yes'}
                                            onChange={(e) => this.setState({ ResidentPrimaryDwelling: 'Yes' })}
                                        >
                                        </input>
                                        <label>Yes</label>
                                        <input type="radio"
                                            name="dwelling"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.ResidentPrimaryDwelling === 'No'}
                                            onChange={(e) => this.setState({ ResidentPrimaryDwelling: 'No' })}
                                        >
                                        </input>
                                        <label>No</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ borderBottom: '2px solid #ddd', paddingTop: '10px', marginBottom: '10px' }}>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" style={{ paddingTop: '10px' }}>
                                    Purchase or Refinance?
                                </td>
                                <td align="left" style={{ paddingTop: '10px' }}>
                                    <div className="radioGroup">
                                        <input type="radio"
                                            name="purchase"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.PurchaseOrRefinance === 'Purchase'}
                                            onChange={(e) => {
                                                this.setState({
                                                    PurchaseOrRefinance: 'Purchase',
                                                    //LoanAmount: undefined
                                                });
                                            }}
                                        >
                                        </input>
                                        <label>Purchase</label>
                                        <input type="radio"
                                            name="purchase"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.PurchaseOrRefinance === 'Refinance'}
                                            onChange={(e) => {
                                                this.setState({ PurchaseOrRefinance: 'Refinance' },
                                                    function () {
                                                        if (this.state.PurchaseOrRefinance === 'Refinance') {
                                                            this.setState({
                                                                PurchaseOrRefinance: 'Refinance',
                                                                //NewOrUsed: undefined,
                                                                //DealerPrivate: undefined,
                                                                //DealerName: undefined,
                                                                //PurchasePrice: undefined,
                                                                //SalesTax: undefined,
                                                                //TotalCost: undefined,
                                                                //TradeBoatEquity: undefined,
                                                                //CashDownPayment: undefined,
                                                                //LoanAmount: undefined
                                                            });
                                                            //this.loanAmountRef.current.focus();
                                                        }
                                                    });
                                            }}
                                        >
                                        </input>
                                        <label>Refinance</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    New or Used?
                                </td>
                                <td align="left">
                                    <div className="radioGroup">
                                        <input type="radio"
                                            name="new"
                                            style={{ marginRight: '5px' }}
                                            //checked={this.state.PurchaseOrRefinance === 'Refinance' ? undefined : this.state.NewOrUsed === 'New'}
                                            checked={this.state.NewOrUsed === 'New'}
                                            onChange={(e) => this.setState({ NewOrUsed: 'New' })}
                                        //disabled={this.state.PurchaseOrRefinance === 'Refinance'}
                                        >
                                        </input>
                                        <label>New</label>
                                        <input type="radio"
                                            name="new"
                                            style={{ marginRight: '5px' }}
                                            //checked={this.state.PurchaseOrRefinance === 'Refinance' ? undefined : this.state.NewOrUsed === 'Used'}
                                            checked={this.state.NewOrUsed === 'Used'}
                                            onChange={(e) => this.setState({ NewOrUsed: 'Used' })}
                                        //disabled={this.state.PurchaseOrRefinance === 'Refinance'}
                                        >
                                        </input>
                                        <label>Used</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    Dealer/Broker or Private Party?
                                </td>
                                <td align="left">
                                    <div className="radioGroup">
                                        <input type="radio"
                                            name="dealer"
                                            style={{ marginRight: '5px' }}
                                            //checked={this.state.PurchaseOrRefinance === 'Refinance' ? undefined : this.state.DealerPrivate === 'Dealer'}
                                            checked={this.state.DealerPrivate === 'Dealer'}
                                            onChange={(e) => this.setState({ DealerPrivate: 'Dealer' })}
                                        //disabled={this.state.PurchaseOrRefinance === 'Refinance'}
                                        >
                                        </input>
                                        <label>Dealer/Broker</label>
                                        {/*{this.state.DealerPrivate === 'Dealer' && this.state.PurchaseOrRefinance !== 'Refinance' ?*/}
                                        {this.state.DealerPrivate === 'Dealer' ?
                                            <input type="text"
                                                onChange={(e) => this.setState({ DealerName: e.target.value })}
                                                value={this.state.DealerName ?? ""}
                                                placeholder="Please list name"
                                                style={{ marginRight: '5px', marginBottom: '0' }}
                                            //disabled={this.state.PurchaseOrRefinance === 'Refinance'}
                                            ></input>
                                            : null}
                                        <input type="radio"
                                            name="dealer"
                                            style={{ marginRight: '5px' }}
                                            //checked={this.state.PurchaseOrRefinance === 'Refinance' ? undefined : this.state.DealerPrivate === 'Private'}
                                            checked={this.state.DealerPrivate === 'Private'}
                                            onChange={(e) => this.setState({ DealerPrivate: 'Private' })}
                                        //disabled={this.state.PurchaseOrRefinance === 'Refinance'}
                                        >
                                        </input>
                                        <label>Private Party</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    Purchase Price
                                </td>
                                <td align="left">
                                    <input type="text"
                                        ref={this.purchasePriceRef}
                                        onChange={(e) => {
                                            var formattedCurrency = e.target.value;
                                            this.setState({ PurchasePrice: formattedCurrency?.toString() },
                                                function () {
                                                    this.calculateTotals();
                                                });
                                        }}
                                        value={this.formatCurrency(this.state.PurchasePrice)}
                                        onKeyPress={(event) => {
                                            var alpha = /^\d*\.?\d*$/;
                                            if (!alpha.test(event.key)) {
                                                event.preventDefault();
                                            }
                                            else {
                                                return true;
                                            }
                                        }}
                                        onPaste={(event) => {
                                            let pasteData = event.clipboardData.getData('Text');
                                            if (pasteData) {
                                                pasteData.replace(/^\d*\.?\d*$/g, '');
                                            }
                                        }}
                                        onBlur={(e) => {
                                            var val = this.state.PurchasePrice;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                PurchasePrice: val
                                            });
                                        }}
                                    //disabled={this.state.PurchaseOrRefinance === 'Refinance'}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    Sales Tax
                                </td>
                                <td align="left">
                                    <input type="text"
                                        onChange={(e) => {
                                            var formattedCurrency = e.target.value;
                                            this.setState({ SalesTax: formattedCurrency?.toString() },
                                                function () {
                                                    this.calculateTotals();
                                                });
                                        }}
                                        value={this.formatCurrency(this.state.SalesTax)}
                                        onKeyPress={(event) => {
                                            var alpha = /^\d*\.?\d*$/;
                                            if (!alpha.test(event.key)) {
                                                event.preventDefault();
                                            }
                                            else {
                                                return true;
                                            }
                                        }}
                                        onPaste={(event) => {

                                            let pasteData = event.clipboardData.getData('Text');
                                            if (pasteData) {
                                                pasteData.replace(/^\d*\.?\d*$/g, '');
                                            }

                                        }}
                                        onBlur={(e) => {
                                            var val = this.state.SalesTax;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                SalesTax: val
                                            });
                                        }}
                                    //disabled={this.state.PurchaseOrRefinance === 'Refinance'}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    Total Cost
                                </td>
                                <td align="left">
                                    <input type="text"
                                        onChange={(e) => {
                                            var formattedCurrency = e.target.value;
                                            this.setState({ TotalCost: formattedCurrency?.toString() },
                                                function () {
                                                    this.calculateTotals();
                                                });
                                        }}
                                        value={this.formatCurrency(this.state.TotalCost)}
                                        onKeyPress={(event) => {
                                            var alpha = /^\d*\.?\d*$/;
                                            if (!alpha.test(event.key)) {
                                                event.preventDefault();
                                            }
                                            else {
                                                return true;
                                            }
                                        }}
                                        onPaste={(event) => {

                                            let pasteData = event.clipboardData.getData('Text');
                                            if (pasteData) {
                                                pasteData.replace(/^\d*\.?\d*$/g, '');
                                            }

                                        }}
                                        onBlur={(e) => {
                                            var val = this.state.TotalCost;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                TotalCost: val
                                            });
                                        }}
                                    //disabled={this.state.PurchaseOrRefinance === 'Refinance'}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    Trade Boat Equity
                                </td>
                                <td align="left">
                                    <input type="text"
                                        onChange={(e) => {
                                            var formattedCurrency = e.target.value;
                                            this.setState({ TradeBoatEquity: formattedCurrency?.toString() },
                                                function () {
                                                    this.calculateTotals();
                                                })
                                        }}
                                        value={this.formatCurrency(this.state.TradeBoatEquity)}
                                        onKeyPress={(event) => {
                                            var alpha = /^\d*\.?\d*$/;
                                            if (!alpha.test(event.key)) {
                                                event.preventDefault();
                                            }
                                            else {
                                                return true;
                                            }
                                        }}
                                        onPaste={(event) => {
                                            let pasteData = event.clipboardData.getData('Text');
                                            if (pasteData) {
                                                pasteData.replace(/^\d*\.?\d*$/g, '');
                                            }
                                        }}
                                        onBlur={(e) => {
                                            var val = this.state.TradeBoatEquity;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                TradeBoatEquity: val
                                            });
                                        }}
                                    //disabled={this.state.PurchaseOrRefinance === 'Refinance'}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    Cash Down Payment
                                </td>
                                <td align="left">
                                    <input type="text"
                                        onChange={(e) => {
                                            var formattedCurrency = e.target.value;
                                            this.setState({ CashDownPayment: formattedCurrency?.toString() },
                                                function () {
                                                    this.calculateTotals();
                                                });
                                        }}
                                        value={this.formatCurrency(this.state.CashDownPayment)}
                                        onKeyPress={(event) => {
                                            var alpha = /^\d*\.?\d*$/;
                                            if (!alpha.test(event.key)) {
                                                event.preventDefault();
                                            }
                                            else {
                                                return true;
                                            }
                                        }}
                                        onPaste={(event) => {

                                            let pasteData = event.clipboardData.getData('Text');
                                            if (pasteData) {
                                                pasteData.replace(/^\d*\.?\d*$/g, '');
                                            }

                                        }}
                                        onBlur={(e) => {
                                            var val = this.state.CashDownPayment;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                CashDownPayment: val
                                            });
                                        }}
                                    //disabled={this.state.PurchaseOrRefinance === 'Refinance'}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    Loan Amount
                                </td>
                                <td align="left">
                                    <input
                                        ref={this.loanAmountRef}
                                        type="text"
                                        onChange={(e) => {
                                            var formattedCurrency = e.target.value;
                                            this.setState({ LoanAmount: formattedCurrency?.toString() });
                                        }}
                                        value={this.formatCurrency(this.state.LoanAmount)}
                                        onBlur={(e) => {
                                            var val = this.state.LoanAmount;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                LoanAmount: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    Term
                                </td>
                                <td align="left">
                                    <div className="radioGroup">
                                        <input type="radio"
                                            name="term"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.Term === '20'}
                                            onChange={(e) => this.setState({ Term: e.target.checked ? "20" : undefined, TermOther: "" })}
                                        ></input>
                                        <label>20 years</label>
                                        <input type="radio"
                                            name="term"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.Term === '15'}
                                            onChange={(e) => this.setState({ Term: e.target.checked ? "15" : undefined, TermOther: "" })}
                                        ></input>
                                        <label>15 years</label>
                                        <input type="radio"
                                            name="term"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.Term === '10'}
                                            onChange={(e) => this.setState({ Term: e.target.checked ? "10" : undefined, TermOther: "" })}
                                        ></input>
                                        <label>10 years</label>
                                        <input type="radio"
                                            name="term"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.Term === "0"}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    this.termOtherRef.current.focus();
                                                }
                                                this.setState({ Term: "0" });
                                            }}
                                        ></input>
                                        <label>Other</label>
                                        <input type="text"
                                            ref={this.termOtherRef}
                                            style={{ width: '50px', marginBottom: '0' }}
                                            maxLength={2}
                                            onChange={(e) => this.setState({ TermOther: e.target.value })}
                                            value={this.state.TermOther}
                                        ></input>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table cellPadding={2} cellSpacing={2}>
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td style={{ fontSize: '15px', fontWeight: '500', paddingTop: '15px' }} align="left">
                                                    Vessel Details
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="number"
                                                        Label="Model Year"
                                                        //MaxNumericValue="9999"
                                                        MaxTextLength={4}
                                                        TextChanged={(e) => {
                                                            this.setState({ VesselYear: e == "" ? null : e });
                                                        }}
                                                        Value={this.state.VesselYear}
                                                        Style={{ width: '100px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput type="text"
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ VesselManufacturer: e })}
                                                        Value={this.state.VesselManufacturer}
                                                        Label="Builder / Manufacturer"
                                                        Style={{ width: '210px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput type="text"
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ VesselModel: e })}
                                                        Value={this.state.VesselModel}
                                                        Label="Model"
                                                        Style={{ width: '210px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="number"
                                                        TextChanged={(e) => this.setState({ VesselLength: e == "" ? null : e })}
                                                        Value={this.state.VesselLength}
                                                        Label="Length"
                                                        Style={{ width: '100px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ VesselType: e })}
                                                        Value={this.state.VesselType}
                                                        Label="Type (i.e. motor yacht, cruiser, etc.)"
                                                        Style={{ width: '210px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ VesselEngineManufacturer: e })}
                                                        Value={this.state.VesselEngineManufacturer}
                                                        Label="Engine(s) Manufacturer"
                                                        Style={{ width: '210px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="number"
                                                        TextChanged={(e) => this.setState({ VesselHP: e })}
                                                        Value={this.state.VesselHP}
                                                        Label="Horsepower"
                                                        Style={{ width: '100px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td align="left" style={{ fontSize: '15px', fontWeight: '500' }}>
                                                    Type
                                                </td>
                                                <td align="left">
                                                    <div className="radioGroup">
                                                        <input type="radio"
                                                            name="type"
                                                            style={{ marginRight: '5px' }}
                                                            checked={this.state.VesselEngineType === 'Single'}
                                                            onChange={(e) => this.setState({ VesselEngineType: e.target.checked ? "Single" : undefined })}
                                                        ></input>
                                                        <label>Single</label>
                                                        <input type="radio"
                                                            name="type"
                                                            style={{ marginRight: '5px' }}
                                                            checked={this.state.VesselEngineType === 'Twin'}
                                                            onChange={(e) => this.setState({ VesselEngineType: e.target.checked ? "Twin" : undefined })}
                                                        ></input>
                                                        <label style={{ marginRight: '18px' }}>Twin</label>
                                                        <input type="radio"
                                                            name="type"
                                                            style={{ marginRight: '8px' }}
                                                            checked={this.state.VesselEngineType === 'Triple'}
                                                            onChange={(e) => this.setState({ VesselEngineType: e.target.checked ? "Triple" : undefined })}
                                                        ></input>
                                                        <label style={{ marginRight: '13px' }}>Triple</label>
                                                        <input type="radio"
                                                            name="type"
                                                            style={{ marginRight: '5px' }}
                                                            checked={this.state.VesselEngineType === 'Quad'}
                                                            onChange={(e) => this.setState({ VesselEngineType: e.target.checked ? 'Quad' : undefined })}
                                                        ></input>
                                                        <label>Quad</label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="left" style={{ fontSize: '15px', fontWeight: '500' }}>
                                                    Fuel
                                                </td>
                                                <td align="left">
                                                    <div className="radioGroup">
                                                        <input type="radio"
                                                            name="fuel"
                                                            style={{ marginRight: '5px' }}
                                                            checked={this.state.VesselEngineFuel === 'Gas'}
                                                            onChange={(e) => this.setState({ VesselEngineFuel: e.target.checked ? "Gas" : undefined })}
                                                        ></input>
                                                        <label style={{ marginRight: '24px' }}>Gas</label>
                                                        <input type="radio"
                                                            name="fuel"
                                                            style={{ marginRight: '5px' }}
                                                            checked={this.state.VesselEngineFuel === 'Diesel'}
                                                            onChange={(e) => this.setState({ VesselEngineFuel: e.target.checked ? "Diesel" : undefined })}
                                                        ></input>
                                                        <label>Diesel</label>
                                                        <input type="radio"
                                                            name="fuel"
                                                            style={{ marginRight: '5px' }}
                                                            checked={this.state.VesselEngineFuel === 'Electric'}
                                                            onChange={(e) => this.setState({ VesselEngineFuel: e.target.checked ? "Electric" : undefined })}
                                                        ></input>
                                                        <label>Electric</label>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3} style={{ fontSize: '15px', fontWeight: '500', paddingTop: '15px' }} align="left">
                                                    Trailer Description
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="number"
                                                        TextChanged={(e) => this.setState({ TrailerYear: e == "" ? null : e })}
                                                        Value={this.state.TrailerYear}
                                                        Label="Trailer Year"
                                                        Style={{ width: '100px' }}
                                                        MaxTextLength={4}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ TrailerMake: e })}
                                                        Value={this.state.TrailerMake}
                                                        Label="Trailer Manufacturer"
                                                        Style={{ width: '200px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ TrailerModel: e })}
                                                        Value={this.state.TrailerModel}
                                                        Label="Trailer Model"
                                                        Style={{ width: '200px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3} style={{ fontSize: '15px', fontWeight: '500', paddingTop: '15px' }} align="left">
                                                    Trade Boat Description
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="number"
                                                        TextChanged={(e) => this.setState({ TradeYear: e == "" ? null : e })}
                                                        Value={this.state.TradeYear}
                                                        Label="Trade Year"
                                                        Style={{ width: '100px' }}
                                                        MaxTextLength={4}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ TradeManufacturer: e })}
                                                        Value={this.state.TradeManufacturer}
                                                        Label="Trade Manufacturer"
                                                        Style={{ width: '200px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ TradeModel: e })}
                                                        Value={this.state.TradeModel}
                                                        Label="Trade Model"
                                                        Style={{ width: '200px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    <br />
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ TradeBoatingExperience: e })}
                                                        Value={this.state.TradeBoatingExperience}
                                                        Label="Boating Experience (last two boats owned)"
                                                        Style={{ width: '300px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="footer">
                    <table style={{ width: '100%', height: '100%' }}>
                        <tbody>
                            <tr>
                                <td align="center" valign="middle">
                                    <button
                                        className="creditAppNext"
                                        style={{ whiteSpace: 'nowrap' }}
                                        disabled={this.state.loading ? true : false}
                                        onClick={() => {
                                            this.save();
                                        }}>
                                        Next
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >
        );
    }
}