import React, { Component } from 'react';
import { _fetch } from './_fetch';
import { Spinner } from 'reactstrap';
import TextInput from './TextInput';
import { customFunctions } from './CustomFunctions.js';
import Popup from './modal';
import Toast from './toast.js';
import { format } from 'date-fns';


export class LoanApplicationStep2 extends Component {
    static displayName = LoanApplicationStep2.name;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            countries: [],
            states: [],
            showModal: false,
            showToast: false,
            toastMessage: '',
            toastItems: [],
            ContinueToken: '',
            emailValid: false
        }
    }

    componentDidMount() {
        try {
            var id = localStorage.getItem("CONTINUE_TOKEN");

            if (id != null) this.setState({ ContinueToken: id });

            var fetch = _fetch();
            fetch.get("/creditapplicationpublic/getcountriesPublic")
                .then((data) => {
                    this.setState({ countries: data });
                });

            fetch.get("/creditapplicationpublic/getstatesPublic")
                .then((data) => {
                    this.setState({ states: data, loading: false });
                    if (id != null) this.loadPage(id);
                });
        }
        catch (err) {
            this.setState({ loading: false });
            console.log('componentDidMount - ' + err);
        }
    }

    loadPage(id) {
        try {
            this.setState({ loading: true });
            var fetch = _fetch();
            fetch.get("/creditapplicationpublic/GetCreditApplicationStep2Public?id=" + id)
                .then((data) => {
                    var _state = this.state;
                    for (const key in data) {
                        if (data[key] != null) {
                            var _key = key.charAt(0).toUpperCase() + key.slice(1);
                            var _value = data[key];
                            _state[_key] = _value;
                        }
                    }
                    this.setState(_state, function () {
                        this.calculateTotal();
                        this.setState({ loading: false });
                        this.setState({ emailValid: customFunctions.validateEmail(this.state.BorrowerEmail ?? "") });
                        this.setState({
                            BorrowerDOB: this.sqlToJsDate(this.state.BorrowerDOB),
                            BorrowerSignatureDate: this.sqlToJsDate(this.state.BorrowerSignatureDate)
                        });
                    });
                });
        }
        catch (err) {
            this.setState({ loading: false });
            console.log('loadPage - ' + err);
        }
    }

    calculateTotal() {
        var s = this.state;
        var borrowerIncomeWages = this.zeroOrValue(s.BorrowerIncomeWages);
        var borrowerIncomeDistributions = this.zeroOrValue(s.BorrowerIncomeDistributions);
        var borrowerIncomeRentalIncome = this.zeroOrValue(s.BorrowerIncomeRentalIncome);
        var borrowerIncomePension = this.zeroOrValue(s.BorrowerIncomePension);
        var borrowerIncomeOther = this.zeroOrValue(s.BorrowerIncomeOther);

        var borrowerIncomeTotal = borrowerIncomeWages + borrowerIncomeDistributions + borrowerIncomeRentalIncome + borrowerIncomePension + borrowerIncomeOther;

        var totalRaw = this.formatCurrency(borrowerIncomeTotal);

        if (totalRaw && totalRaw.startsWith('$') && !totalRaw.includes('.')) {
            totalRaw = totalRaw + '.00';
        }
        else if (totalRaw.startsWith('$') && totalRaw.includes('.')) {
            var spl = totalRaw.split(".");
            if (spl[1].length == 0) {
                totalRaw = totalRaw + '00';
            }

            if (spl[1].length == 1) {
                totalRaw = totalRaw + '0';
            }
        }

        this.setState({ BorrowerIncomeTotal: totalRaw });
    }

    zeroOrValue(value) {
        if (value == undefined || value == 0 || value == null) return 0;
        //if (isNaN(value)) {
        //    value = value.toString().substring(1, value.toString().lenth - 1);
        //}
        var _value = value?.toString().replace(/,/g, '').replace('$', '');

        if (_value.length > 0) {

            var result = (isNaN(_value) || _value == undefined) ? 0 : parseFloat(_value);
            return result;
        }
        else {
            return 0;
        }
    }

    formatCurrency(value) {
        if (value == undefined) return "";
        var _values = value.toString().replace(/,/g, '').replace('$', '');

        var dec = "";
        var _value = "";

        var elements = _values.split('.');
        if (elements?.length == 1) {
            _value = _values.split('.')[0];
        }
        else if (elements?.length > 1) {
            _value = _values.split('.')[0];
            dec = "." + _values.split('.')[1].substring(0, 2);
        }
        else {
            _value = _values;
        }
        if (_value.length > 0) {
            return new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }).format(_value) + dec;
        }
    }

    save(url) {
        try {
            var item = [];

            if (url == undefined) {
                if (this.state.BorrowerFirstName == undefined || this.state.BorrowerFirstName?.length == 0) {
                    item.push("First Name");
                }
                if (this.state.BorrowerLastName == undefined || this.state.BorrowerLastName?.length == 0) {
                    item.push("Last Name");
                }
                if (this.state.BorrowerSSN == undefined || this.state.BorrowerSSN?.length == 0) {
                    item.push("SSN");
                }
                if (this.state.BorrowerSSN?.length < 11) {
                    item.push("SSN");
                }
                if (this.state.BorrowerDOB == undefined || this.state.BorrowerDOB?.length == 0) {
                    item.push("Date of Birth");
                }
                if (this.state.BorrowerUSCitizen === undefined) {
                    item.push("US Citizen");
                }
                if (this.state.BorrowerUSCitizen !== undefined &&
                    this.state.BorrowerUSCitizen === "No" &&
                    (this.state.BorrowerUSCitizenCountry === undefined || this.state.BorrowerUSCitizenCountry === 0)) {
                    item.push("Non US Citizen Country");
                }
                if ((this.state.BorrowerCellPhone === undefined || this.state.BorrowerCellPhone?.length === 0) &&
                    (this.state.BorrowerOtherPhone === undefined || this.state.BorrowerOtherPhone?.length === 0)) {
                    item.push("Cell Phone or Other Phone");
                }
                if ((this.state.BorrowerCellPhone?.length < 10 && this.state.BorrowerCellPhone?.length > 0) ||
                    (this.state.BorrowerOtherPhone?.length < 10 && this.state.BorrowerOtherPhone?.length > 0)) {
                    item.push("Cell Phone or Other Phone");
                }
                if (this.state.BorrowerEmail == undefined || this.state.BorrowerEmail?.length == 0) {
                    item.push("Email");
                }
                if (!this.state.emailValid) {
                    item.push("Email");
                }
                if (localStorage.getItem("APP_TYPE") != null && localStorage.getItem("APP_TYPE") == "Individual" && (this.state.BorrowerSignature == undefined || this.state.BorrowerSignature.length == 0)) {
                    item.push("Signature");
                }
                if (localStorage.getItem("APP_TYPE") != null && localStorage.getItem("APP_TYPE") == "Individual" && (this.state.BorrowerSignatureDate == undefined || this.state.BorrowerSignatureDate.length == 0)) {
                    item.push("Signature Date");
                }
            }

            if (item.length == 0) {
                this.setState({ loading: true });
                var fetch = _fetch();
                //this.setState({
                //    BorrowerDOB: this.state.BorrowerDOB === undefined || this.state.BorrowerDOB == "" ? null : format(new Date(this.state.BorrowerDOB.replace(/-/g, '/')), "yyyy-MM-dd"),
                //    BorrowerSignatureDate: this.state.BorrowerSignatureDate === undefined || this.state.BorrowerSignatureDate == "" ? null : format(new Date(this.state.BorrowerSignatureDate.replace(/-/g, '/')), "yyyy-MM-dd")
                //},
                //    function () {
                fetch.post("/creditapplicationPublic/SaveCreditApplicationStep2Public", this.state)
                    .then((id) => {
                        if (id) {
                            if (url == undefined) {
                                this.setState({ loading: false, LastStep: "2" });
                                if (localStorage.getItem("APP_TYPE") != null && localStorage.getItem("APP_TYPE") == "Individual") {
                                    this.setState({ showModal: true });
                                }
                                else {
                                    window.location.href = '/LoanApplicationStep3';
                                }
                            }
                            else {
                                window.location.href = url;
                            }
                        }
                        else {
                            this.setState({
                                showToast: true,
                                toastMessage: 'There was an issue saving your information. Please try again',
                                loading: false
                            });
                        }
                    });
                //});
            }
            else {
                this.setState({
                    showToast: true,
                    toastMessage: 'The following fields are required:',
                    toastItems: item
                });
                setTimeout(function () { document.getElementById("maincontainer").scrollTo({ top: 0, behavior: 'smooth' }); }, 250);
            }
        }
        catch (err) {
            this.setState({ loading: false });
            console.log('save - ' + err);
        }
    }

    sqlToJsDate(sqlDate) {
        if (sqlDate == "" || sqlDate === undefined) return sqlDate;

        var dateParts = sqlDate.split("T");
        return dateParts[0];
    }

    lastNameOnBlur() {
        let s = this.state;
        var f = s.BorrowerFirstName == undefined ? "" : s.BorrowerFirstName.toString() + " ";
        var m = s.BorrowerMiddleInitial == undefined ? "" : s.BorrowerMiddleInitial.toString() + " ";
        var l = s.BorrowerLastName == undefined ? "" : s.BorrowerLastName?.toString();
        var sig = f + m + l;

        sessionStorage.setItem("BorrowerSignature", sig);
    }

    render() {
        return (
            <div className="container-top">
                <Toast
                    toastText={this.state.toastMessage}
                    listItems={this.state.toastItems}
                    showToast={this.state.showToast}
                    onToastClose={() => this.setState({ showToast: false })}
                    autoHideToastDelay={10000}
                />
                <div id="maincontainer" className="container-for-fixed-header">
                    {this.state.loading === true ?
                        <div>
                            <Spinner color="#000" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                        </div>
                        : null
                    }
                    <table cellSpacing={2} cellPadding={2}>
                        <tbody>
                            <tr>
                                <td align="left" colSpan={2}>
                                    <label style={{ fontSize: '22px', fontWeight: '500', color: '#596db3' }}>Borrower Information</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td align="left" valign="bottom">
                                                    <TextInput
                                                        Type="text"
                                                        Label="First Name"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerFirstName: e }, function () {
                                                                this.lastNameOnBlur();
                                                            });
                                                        }}
                                                        Value={this.state.BorrowerFirstName ?? ""}
                                                        Style={{ width: '175px' }}
                                                    />
                                                </td>
                                                <td align="left" valign="bottom">
                                                    <TextInput
                                                        Type="text"
                                                        Label="M.I"
                                                        MaxTextLength={1}
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerMiddleInitial: e }, function () {
                                                                this.lastNameOnBlur();
                                                            });
                                                        }}
                                                        Value={this.state.BorrowerMiddleInitial ?? ""}
                                                        Style={{ width: '60px' }}
                                                    />
                                                </td>
                                                <td align="left" valign="bottom">
                                                    <TextInput
                                                        Type="text"
                                                        Label="Last Name"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerLastName: e }, function () {
                                                                this.lastNameOnBlur();
                                                            });
                                                        }}
                                                        Value={this.state.BorrowerLastName ?? ""}
                                                        Style={{ width: '175px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td align="left" valign="bottom">
                                                    <TextInput
                                                        Type="ssn"
                                                        Label="SSN"
                                                        MaxTextLength={11}
                                                        TextChanged={(e) => {
                                                            var formattedSSN = e;
                                                            this.setState({ BorrowerSSN: formattedSSN });
                                                        }}
                                                        Value={this.state.BorrowerSSN ?? ""}
                                                        Style={{ width: '175px' }}
                                                    />
                                                </td>
                                                <td align="left" valign="bottom">
                                                    <TextInput
                                                        Type="date"
                                                        Label="Date of Birth"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerDOB: e });
                                                        }}
                                                        Value={this.state.BorrowerDOB != undefined ? this.sqlToJsDate(this.state.BorrowerDOB) : ""}
                                                        Style={{ width: '120px' }}
                                                    />
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td align="left" valign="middle">
                                                    US Citizen?
                                                </td>
                                                <td align="left" valign="middle">
                                                    <div className="radioGroup">
                                                        <input type="radio"
                                                            name="country"
                                                            style={{ marginRight: '5px' }}
                                                            onChange={(e) => {
                                                                this.setState({ BorrowerUSCitizen: e.target.checked ? "Yes" : "No" });
                                                            }}
                                                            checked={this.state.BorrowerUSCitizen === "Yes"}
                                                        ></input>
                                                        <label>Yes</label>
                                                        <input type="radio"
                                                            name="country"
                                                            style={{ marginRight: '5px' }}
                                                            onChange={(e) => {
                                                                this.setState({ BorrowerUSCitizen: e.target.checked ? "No" : "Yes" });
                                                            }}
                                                            checked={this.state.BorrowerUSCitizen === "No"}
                                                        ></input>
                                                        <label>No</label>
                                                        {this.state.BorrowerUSCitizen === "No" ?
                                                            <select
                                                                value={this.state.BorrowerUSCitizenCountry}
                                                                onChange={(e) => {
                                                                    this.setState({ BorrowerUSCitizenCountry: e.target.value })
                                                                }}
                                                            >
                                                                <option key={0} value={0}>Select a Country</option>
                                                                {this.state.countries.map((item, idx) =>
                                                                    <option key={idx} value={item.name}>{item.name}</option>
                                                                )}
                                                            </select>
                                                            : null}
                                                    </div>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td align="left" valign="bottom">
                                                    <TextInput
                                                        Type="phone"
                                                        Label="Cell Phone"
                                                        MaxTextLength={14}
                                                        TextChanged={(e) => {
                                                            var formattedPhone = customFunctions.formatPhoneNumber(e);
                                                            this.setState({ BorrowerCellPhone: formattedPhone });
                                                        }}
                                                        Value={customFunctions.formatPhoneNumber(this.state.BorrowerCellPhone) ?? ""}
                                                        Style={{ width: '175px' }}
                                                    />
                                                </td>
                                                <td align="left" valign="bottom">
                                                    <TextInput
                                                        Type="phone"
                                                        Label="Other Phone"
                                                        MaxTextLength={14}
                                                        TextChanged={(e) => {
                                                            var formattedPhone = customFunctions.formatPhoneNumber(e);
                                                            this.setState({ BorrowerOtherPhone: formattedPhone });
                                                        }}
                                                        Value={customFunctions.formatPhoneNumber(this.state.BorrowerOtherPhone) ?? ""}
                                                        Style={{ width: '175px' }}
                                                    />
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td colSpan={2} align="left" valign="bottom">
                                                    <TextInput
                                                        Type="email"
                                                        Label="Email"
                                                        TextChanged={(e) => {
                                                            this.setState({ emailValid: customFunctions.validateEmail(e) });
                                                            this.setState({ BorrowerEmail: e });
                                                        }}
                                                        Value={this.state.BorrowerEmail ?? ""}
                                                        Style={{ width: '300px' }}
                                                    />
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <td align="left" style={{ paddingRight: '3vw' }} >
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td style={{ fontSize: '15px', fontWeight: '500', paddingTop: '15px' }} align="left">
                                                    Current Residence
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table cellPadding={2} cellSpacing={2}>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={3} align="left" valign="bottom">
                                                                    <TextInput
                                                                        Type="text"
                                                                        Label="Home Address"
                                                                        TextChanged={(e) => {
                                                                            this.setState({ BorrowerCurrentAddress: e });
                                                                        }}
                                                                        Value={this.state.BorrowerCurrentAddress ?? ""}
                                                                        Style={{ width: '257px' }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left" valign="bottom">
                                                                    <TextInput
                                                                        Type="text"
                                                                        Label="City"
                                                                        TextChanged={(e) => {
                                                                            this.setState({ BorrowerCurrentCity: e });
                                                                        }}
                                                                        Value={this.state.BorrowerCurrentCity ?? ""}
                                                                        Style={{ width: '130px' }}
                                                                    />
                                                                </td>
                                                                <td align="left" valign="bottom">
                                                                    <select
                                                                        value={this.state.BorrowerCurrentStateId}
                                                                        onChange={(e) => {
                                                                            this.setState({ BorrowerCurrentStateId: e.target.value })
                                                                        }}
                                                                    >
                                                                        <option key={0} value={0}>--</option>
                                                                        {this.state.states.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                </td>
                                                                <td align="left" valign="bottom">
                                                                    <TextInput
                                                                        Type="number"
                                                                        MaxTextLength={5}
                                                                        Label="Zip"
                                                                        TextChanged={(e) => {
                                                                            this.setState({ BorrowerCurrentZip: e });
                                                                        }}
                                                                        Value={this.state.BorrowerCurrentZip ?? ""}
                                                                        Style={{ width: '60px' }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left" valign="bottom">
                                                                    <TextInput
                                                                        Type="number"
                                                                        Label="# Years"
                                                                        TextChanged={(e) => {
                                                                            this.setState({ BorrowerCurrentYears: e });
                                                                        }}
                                                                        Value={this.state.BorrowerCurrentYears ?? ""}
                                                                        Style={{ width: '60px' }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td align="left">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3} style={{ fontSize: '15px', fontWeight: '500', paddingTop: '15px' }} align="left">
                                                    Previous Residence
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table cellPadding={2} cellSpacing={2}>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={3} align="left" valign="bottom">
                                                                    <TextInput
                                                                        Type="text"
                                                                        Label="Home Address"
                                                                        TextChanged={(e) => {
                                                                            this.setState({ BorrowerPreviousAddress: e });
                                                                        }}
                                                                        Value={this.state.BorrowerPreviousAddress ?? ""}
                                                                        Style={{ width: '257px' }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left" valign="bottom">
                                                                    <TextInput
                                                                        Type="text"
                                                                        Label="City"
                                                                        TextChanged={(e) => {
                                                                            this.setState({ BorrowerPreviousCity: e });
                                                                        }}
                                                                        Value={this.state.BorrowerPreviousCity ?? ""}
                                                                        Style={{ width: '130px' }}
                                                                    />
                                                                </td>
                                                                <td align="left" valign="bottom">
                                                                    <select
                                                                        value={this.state.BorrowerPreviousStateId}
                                                                        onChange={(e) => {
                                                                            this.setState({ BorrowerPreviousStateId: e.target.value })
                                                                        }}
                                                                    >
                                                                        <option key={0} value={0}>--</option>
                                                                        {this.state.states.map((item, idx) =>
                                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                                        )}
                                                                    </select>
                                                                </td>
                                                                <td align="left" valign="bottom">
                                                                    <TextInput
                                                                        Type="number"
                                                                        MaxTextLength={5}
                                                                        Label="Zip"
                                                                        TextChanged={(e) => {
                                                                            this.setState({ BorrowerPreviousZip: e });
                                                                        }}
                                                                        Value={this.state.BorrowerPreviousZip ?? ""}
                                                                        Style={{ width: '60px' }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left" valign="bottom">
                                                                    <TextInput
                                                                        Type="number"
                                                                        Label="# Years"
                                                                        TextChanged={(e) => {
                                                                            this.setState({ BorrowerPreviousYears: e });
                                                                        }}
                                                                        Value={this.state.BorrowerPreviousYears ?? ""}
                                                                        Style={{ width: '60px' }}
                                                                    />
                                                                </td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <td align="left">
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td align="left" valign="middle">
                                                    Own or Rent?
                                                </td>
                                                <td align="left" valign="middle">
                                                    <div className="radioGroup">
                                                        <input type="radio"
                                                            name="ownRent"
                                                            style={{ marginRight: '5px' }}
                                                            onChange={(e) => {
                                                                this.setState({ BorrowerCurrentOwnOrRent: e.target.checked ? "Own" : "Rent" });
                                                            }}
                                                            checked={this.state.BorrowerCurrentOwnOrRent === "Own"}
                                                        ></input>
                                                        <label>Own</label>
                                                        <input type="radio"
                                                            name="ownRent"
                                                            style={{ marginRight: '5px' }}
                                                            onChange={(e) => {
                                                                this.setState({ BorrowerCurrentOwnOrRent: e.target.checked ? "Rent" : "Own" });
                                                            }}
                                                            checked={this.state.BorrowerCurrentOwnOrRent === "Rent"}
                                                        ></input>
                                                        <label>Rent</label>
                                                    </div>
                                                </td>
                                                <td>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" valign="bottom">
                                    <TextInput
                                        Type="custom"
                                        Label="Monthly Mortgage / Rent"
                                        TextChanged={(e) => {
                                            this.setState({ BorrowerCurrentMortgageRent: e });
                                        }}
                                        Value={this.formatCurrency(this.state.BorrowerCurrentMortgageRent) ?? ""}
                                        Style={{ width: '170px' }}
                                    />
                                </td>
                                <td align="left" valign="bottom">
                                </td>
                                <td align="left" valign="bottom">
                                </td>
                            </tr>
                            <tr>
                                <td align="left" style={{ paddingRight: '3vw' }}>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td style={{ fontSize: '15px', fontWeight: '500', paddingTop: '15px' }} align="left">
                                                    Employment
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} align="left" valign="bottom">
                                                    <TextInput
                                                        Type="text"
                                                        Label="Company or Employer Name"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerCurrentEmployerName: e });
                                                        }}
                                                        Value={this.state.BorrowerCurrentEmployerName ?? ""}
                                                        Style={{ width: '257px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} align="left" valign="bottom">
                                                    <TextInput
                                                        Type="text"
                                                        Label="Type of Business"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerCurrentEmployerType: e });
                                                        }}
                                                        Value={this.state.BorrowerCurrentEmployerType ?? ""}
                                                        Style={{ width: '257px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} align="left" valign="bottom">
                                                    <TextInput
                                                        Type="text"
                                                        Label="Position"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerCurrentEmployerPosition: e });
                                                        }}
                                                        Value={this.state.BorrowerCurrentEmployerPosition ?? ""}
                                                        Style={{ width: '257px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} align="left" valign="bottom">
                                                    <TextInput
                                                        Type="number"
                                                        Label="# Years"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerCurrentEmployerYears: e });
                                                        }}
                                                        Value={this.state.BorrowerCurrentEmployerYears ?? ""}
                                                        Style={{ width: '60px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} align="left" valign="bottom">
                                                    <TextInput
                                                        Type="text"
                                                        Label="Address"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerCurrentEmployerAddress: e });
                                                        }}
                                                        Value={this.state.BorrowerCurrentEmployerAddress ?? ""}
                                                        Style={{ width: '257px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="left" valign="bottom">
                                                    <TextInput
                                                        Type="text"
                                                        Label="City"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerCurrentEmployerCity: e });
                                                        }}
                                                        Value={this.state.BorrowerCurrentEmployerCity ?? ""}
                                                        Style={{ width: '130px' }}
                                                    />
                                                </td>
                                                <td align="left" valign="bottom">
                                                    <select
                                                        value={this.state.BorrowerCurrentEmployerStateId}
                                                        onChange={(e) => {
                                                            this.setState({ BorrowerCurrentEmployerStateId: e.target.value })
                                                        }}
                                                    >
                                                        <option key={0} value={0}>--</option>
                                                        {this.state.states.map((item, idx) =>
                                                            <option key={idx} value={item.id}>{item.name}</option>
                                                        )}
                                                    </select>
                                                </td>
                                                <td align="left" valign="bottom">
                                                    <TextInput
                                                        Type="number"
                                                        MaxTextLength={5}
                                                        Label="Zip"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerCurrentEmployerZip: e });
                                                        }}
                                                        Value={this.state.BorrowerCurrentEmployerZip ?? ""}
                                                        Style={{ width: '60px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td align="right" valign="top">
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td style={{ fontSize: '15px', fontWeight: '500', paddingTop: '15px' }} align="left">
                                                    Previous Employment
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} align="left" valign="bottom">
                                                    <TextInput
                                                        Type="text"
                                                        Label="Company or Employer Name"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerPreviousEmployerName: e });
                                                        }}
                                                        Value={this.state.BorrowerPreviousEmployerName ?? ""}
                                                        Style={{ width: '257px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} align="left" valign="bottom">
                                                    <TextInput
                                                        Type="text"
                                                        Label="Type of Business"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerPreviousEmployerType: e });
                                                        }}
                                                        Value={this.state.BorrowerPreviousEmployerType ?? ""}
                                                        Style={{ width: '257px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} align="left" valign="bottom">
                                                    <TextInput
                                                        Type="text"
                                                        Label="Position"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerPreviousEmployerPosition: e });
                                                        }}
                                                        Value={this.state.BorrowerPreviousEmployerPosition ?? ""}
                                                        Style={{ width: '257px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} align="left" valign="bottom">
                                                    <TextInput
                                                        Type="number"
                                                        Label="# Years"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerPreviousEmployerYears: e });
                                                        }}
                                                        Value={this.state.BorrowerPreviousEmployerYears ?? ""}
                                                        Style={{ width: '60px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <td colSpan={6} style={{ fontSize: '15px', fontWeight: '500', paddingTop: '15px' }} align="left">
                                    Annual Income
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Wages"
                                        TextChanged={(e) => {
                                            this.setState({ BorrowerIncomeWages: e }, function () { this.calculateTotal(); });
                                        }}
                                        Value={this.formatCurrency(this.state.BorrowerIncomeWages ?? "")}
                                        Style={{ width: '100px' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Distributions"
                                        TextChanged={(e) => {
                                            this.setState({ BorrowerIncomeDistributions: e }, function () { this.calculateTotal(); });
                                        }}
                                        Value={this.formatCurrency(this.state.BorrowerIncomeDistributions ?? "")}
                                        Style={{ width: '100px' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Rental Income"
                                        TextChanged={(e) => {
                                            this.setState({ BorrowerIncomeRentalIncome: e }, function () { this.calculateTotal(); });
                                        }}
                                        Value={this.formatCurrency(this.state.BorrowerIncomeRentalIncome ?? "")}
                                        Style={{ width: '100px' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Pension"
                                        TextChanged={(e) => {
                                            this.setState({ BorrowerIncomePension: e }, function () { this.calculateTotal(); });
                                        }}
                                        Value={this.formatCurrency(this.state.BorrowerIncomePension ?? "")}
                                        Style={{ width: '100px' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Other"
                                        TextChanged={(e) => {
                                            this.setState({ BorrowerIncomeOther: e }, function () { this.calculateTotal(); });
                                        }}
                                        Value={this.formatCurrency(this.state.BorrowerIncomeOther ?? "")}
                                        Style={{ width: '100px' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Total"
                                        TextChanged={(e) => {
                                            this.setState({ BorrowerIncomeTotal: e }, function () { this.calculateTotal(); });
                                        }}
                                        Value={this.formatCurrency(this.state.BorrowerIncomeTotal ?? "")}
                                        Style={{ width: '100px' }}
                                        Disabled={true}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {localStorage.getItem("APP_TYPE") != null && localStorage.getItem("APP_TYPE") == "Individual" ?
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={2} align="left" style={{ paddingTop: '15px' }}>
                                                        <div className="disclaimer">
                                                            I authorize Fairwind Financial Corporation, its Assigns and/or their Lender(s) to obtain information, including credit inquiries/reports, employment history and any other information necessary to evaluate credit.  The applicant and/or co-applicant may ask whether Fairwind Financial Corporation obtained such a report.  If such a report has been obtained, the applicant and/or the co-applicant may request the name and address of the reporting agency that provided the report. This application shall remain the property of Fairwind Financial Corporation, its Assigns and/or Lender(s), whether or not said credit is granted.<br /><br />
                                                            Income derived from alimony, child support or other maintenance payments need not be disclosed when applying for credit.<br /><br />
                                                            All the statements I have made are true, correct and complete, I understand you will rely upon their accuracy when determining whether or not said credit is granted.<br /><br />
                                                            PATRIOT ACT NOTICE: To help the government fight the funding of terrorism and money laundering activities, Federal Law requires all financial institutions to obtain, verify and record information that identifies each person who opens an account. For purposes of this section, account shall be understood to include loan accounts.<br /><br />
                                                            You agree as follows: On each phone number that you provide to us on this application, whether cell phone or landline, you consent to us, our assigns, lenders and agents, of any of us contacting you at the number by calling, texting or any other electronic communication from time to time for any reason about your accounts, including but not limited to, for collection and payment purposes. You agree that automated dialing, equipment or pre-recorded voice message maybe used for any of those purposes.<br /><br />
                                                            If this is an application for joint credit with another person, complete all sections of page 2, by providing information in the Co-Borrower section, and by initialing below, you confirm your intent to apply for joint credit.<br /><br />
                                                            By completing and submitting this elctronic application, I consent to conduct business electronically with Fairwind Financial Corporation, and that this electroinc communiction will have the same legal capacity as any written and/or sign paper application or communication.  In addition, your signature below confirms your consent to receive all the disclosures noted on the application electronically.  If you wish to receive a paper copy of the Disclosures, you can do so my requesting a copy from <a href="mailto:info@fairwindfinancial.com">info@fairwindfinancial.com</a><br /><br />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div style={{ display: 'flex', flexDirection: 'row', width: 'fit-content', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                            <input
                                                                type="checkbox"
                                                                checked={this.state.AcknowledgeDisclosuresAndDigitalSignature ?? false}
                                                                onChange={(e) => {
                                                                    this.setState({ AcknowledgeDisclosuresAndDigitalSignature: !this.state.AcknowledgeDisclosuresAndDigitalSignature })
                                                                }}
                                                                style={{ marginRight: '5px' }}
                                                            />
                                                            <label style={{ marginBottom: '0', fontSize: '12px' }}>I acknowledge that I have read the disclosures and will be providing a digital signature when submitting this loan application.</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table style={{ marginTop: '10px', marginBottom: '10px' }}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <TextInput
                                                            Type="text"
                                                            Label="Borrower Signature"
                                                            TextChanged={(e) => {
                                                                this.setState({ BorrowerSignature: e });
                                                            }}
                                                            Value={this.state.BorrowerSignature ?? ""}
                                                            Style={{ width: '257px', fontFamily: 'Imperial Script', fontSize: '18px' }}
                                                        />
                                                    </td>
                                                    <td>
                                                        <TextInput
                                                            Type="date"
                                                            Label="Date"
                                                            TextChanged={(e) => {
                                                                this.setState({ BorrowerSignatureDate: e });
                                                            }}
                                                            Value={this.state.BorrowerSignatureDate !== undefined && this.state.BorrowerSignatureDate !== null ? this.sqlToJsDate(this.state.BorrowerSignatureDate) : this.setState({ BorrowerSignatureDate: customFunctions.formatDateOrTimeForInput(new Date().toLocaleDateString('en-US'), 'date') })}
                                                            Style={{ width: '7vw' }}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        : null}
                </div>
                <div className="footer-center-content">
                    <table style={{ height: '100%' }}>
                        <tbody>
                            <tr>
                                <td align="center" valign="middle">
                                    <button
                                        className="creditAppNext"
                                        style={{ whiteSpace: 'nowrap' }}
                                        disabled={this.state.loading}
                                        onClick={() => {
                                            this.save("/LoanApplicationStep1");
                                        }}
                                    >Back
                                    </button>
                                </td>
                                <td align="center" valign="middle">
                                    {sessionStorage.getItem("USER_SESSION") != null && sessionStorage.getItem("USER_SESSION") == "true" ?
                                        <button
                                            className="creditAppNext"
                                            style={{ whiteSpace: 'nowrap' }}
                                            disabled={this.state.loading || !this.state.AcknowledgeDisclosuresAndDigitalSignature}
                                            onClick={() => {
                                                this.save();
                                            }}
                                        >Next
                                        </button>
                                        :
                                        localStorage.getItem("APP_TYPE") != null && localStorage.getItem("APP_TYPE") == "Individual" ?
                                            <button
                                                className="creditAppNext"
                                                style={{ whiteSpace: 'nowrap' }}
                                                disabled={this.state.loading || localStorage.getItem("APP_TYPE") != null && localStorage.getItem("APP_TYPE") == "Individual" ? !this.state.AcknowledgeDisclosuresAndDigitalSignature : false}
                                                onClick={() => {
                                                    this.save();
                                                }}
                                            >Submit Application
                                            </button> :
                                            <button
                                                className="creditAppNext"
                                                style={{ whiteSpace: 'nowrap' }}
                                                disabled={this.state.loading}
                                                onClick={() => {
                                                    this.save();
                                                }}
                                            >Next
                                            </button>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Popup
                    headerText={"Important Notice"}
                    bodyText={"Loan requests in excess of $150,000 typically require a completed Personal Financial Statement.  If you do not complete it now, you may be required to completed it prior to receiving a credit decision."}
                    button1Text={"Continue to the Personal Financial Statement"}
                    showButton2={true}
                    button2Text={"Skip Personal Financial Statement and Submit Application"}
                    button1ClickHandler={this.handleButton1Click}
                    button2ClickHandler={this.handleButton2Click}
                    showModal={this.state.showModal}
                />
            </div>
        )
    }

    handleButton1Click() {
        window.location.href = "/ApplicantPersonalFinancialStatement";
    }

    handleButton2Click() {
        window.location.href = "/LoanApplicationReceived";
    }
}