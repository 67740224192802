import React, { Component } from 'react';
import { Container, Row, Col, Input, Button, Alert } from 'reactstrap';
import { RowItem } from '../layout/RowItem';
import authService from '../api-authorization/AuthorizeService';
import AdminFunctions from './AdminFunctions';
import '../layout/site.css';

export class LocationDetails extends Component {
    static displayName = LocationDetails.name;

    constructor(props) {
        super(props);

        this.state = {
            locationId: 0,
            locationName: '',
            success: undefined
        };

    }

    async componentDidMount() {
        const isAdmin = AdminFunctions.isAdminAsync();
        if(!isAdmin) {
            this.props.history.push('/accessdenied');
        }        
        else {
            if (this.props.location.state !== undefined) {
                if(this.props.location.state.existingLocation) {
                    const locationId = this.props.location.state.locationId;
                    this.setState({ locationId: locationId });
                    this.getLocationDetails(locationId);
                }
            }
            else {
                this.props.history.push('/admin/locations/create');
            }
        }
    }

    render() {
        return (
            <div>
                <Container className='container-main'>
                    <RowItem>
                        <Col xl='12'>
                            <h3>Location Details</h3>
                        </Col>
                        <Col xl='12'>
                            <h6>Location Id: {this.state.locationId}</h6>
                        </Col>
                    </RowItem>
                    <RowItem>
                        <Col xl='12'>
                            <h6>Edit Location Name</h6>
                        </Col>    
                        <Col xl='10' lg='10' md='10' sm='10' xs='10'>
                            <Input
                                type='text'
                                value={this.state.locationName}
                                onChange={(e) => this.setState({ locationName: e.target.value })}
                                name='locationName'
                                id='locationName'
                                placeholder='Name'
                            />
                        </Col>
                        <Col xl='2' lg='2' md='2' sm='2' xs='2'>
                            <Button
                                color='primary'                          
                                onClick={() => this.editLocation()}      
                            >
                                Save
                            </Button>
                        </Col>
                    </RowItem>                  
                    <Row className='row-20'>
                        <Col>
                            {this.state.success === true && (
                                <Alert color='success'>
                                    Location saved successfully!
                                </Alert>
                            )}                            
                            {this.state.success === false && (
                                <Alert color='danger'>
                                    Error saving Location!
                                </Alert>
                            )}
                        </Col>
                    </Row>                                       
                </Container>
            </div>
        )
    }

    async getLocationDetails(locationId) {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/Admin/GetLocationDetailsAsync?id=${locationId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({
            locationName: data.name
        });
    }

    async editLocation() {
        const token = await authService.getAccessToken();
        const name = this.state.locationName;
        if(name) {
            const dto = {
                id: this.state.locationId,
                name: name
            };
            const response = await fetch('api/Admin/EditLocationAsync', {
                method: 'POST',
                body: JSON.stringify(dto),
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
            });
            const data = await response.json();
            this.setState({ success: data });
        }
    }

}