import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            roles: []
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])

        if (isAuthenticated) {
            const token = await authService.getAccessToken();
            const response = await fetch(`/CreditApplication/getUserRoles?userId=` + user.sub, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });

            const data = await response.json();

            this.setState({
                roles: data
            });
        }
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    AdminLink = () => {
        const location = useLocation();

        return (
            <div style={{ display: 'flex', width: '300px', justifyContent: 'flex-end', alignItems: 'flex-end', cursor: 'pointer' }}>
                <div style={{ fontWeight: 'bold', color: '#0000cc', width: '100%', textAlign: 'right', padding: '5px 7px', borderTop: '1px solid #00000015' }} onClick={() => { location.pathname === '/Admin' ? window.location.href = "/" : window.location.href = "/Admin" }}>{location.pathname === '/Admin' ? "Home" : "Administration"}</div>
            </div>
        );
    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (
            this.state.roles.length > 0 && this.state.roles.includes('Admin') ?

                <div style={{ display: 'flex', width: '400px', height: 'fit-content', flexDirection: 'column', justifyContent: 'flex-end', alignItems:'flex-end' }}>
                    <div style={{ display: 'flex', width: '400px', justifyContent: 'flex-end', whiteSpace:'nowrap' }}>
                        <Fragment>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to={logoutPath}><b>Logout</b></NavLink>
                            </NavItem>
                        </Fragment>
                    </div>
                    <this.AdminLink />
                </div>
                :
                <Fragment>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to={logoutPath}>Logout</NavLink>
                    </NavItem>
                </Fragment>
        );

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>*/}
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>*/}
            {/*</NavItem>*/}
        </Fragment>);
    }
}
