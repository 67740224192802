import axios from "axios";

export default axios.create({
    // '### release - change URL
    //baseURL: process.env.NODE_ENV === "development" ? "https://localhost:5001/" : "https://app.fairwindfinancial.com/",
    baseURL: "https://app.fairwindfinancial.com/",
    //baseURL: "https://ff.swipsystems.com/",
    //baseURL: "https://apptest.fairwindfinancial.com/",
    headers: {
        "Content-type": "application/json"
    }
});
