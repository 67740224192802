import React, { Component } from 'react';
import { Container, Row, Col, Input, Table, Button } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import AdminFunctions from './AdminFunctions';
import utilities from '../util/utilities';
import { FaSort } from 'react-icons/fa'
import '../layout/site.css';

export class Locations extends Component {
    static displayName = Locations.name;

    constructor(props) {
        super(props);

        this.state = {
            search: null,
            locations: [],
            height: 0,
            width: 0,
            sort: {
                column: null,
                direction: 'desc'
            },
        };

        window.addEventListener('resize', this.setWindowDimensions);        
    }

    async componentDidMount() {
        const isAdmin = AdminFunctions.isAdminAsync();
        if(!isAdmin) {
            this.props.history.push('/accessdenied');
        }
        this.setWindowDimensions();
        this.getLocations();        
    }

    render() {
        return (
            <div>
                <Container className='container-main'>
                    <Row>
                        <Col>
                            <h3>Locations</h3>
                        </Col>
                    </Row>
                    <Row className='row-20'>
                        <Col xl='10' lg='10' md='12' sm='12' xs='12'>
                            <Input
                                type='text'
                                onChange={(e) => this.setState({ search: e.target.value })}
                                name='search'
                                id='search'
                                placeholder='Search'
                                style={ this.state.width < utilities.dimensions.LG ? { marginBottom: '20px' } : {}}
                            />
                        </Col>
                        <Col xl='1' lg='1' md='2' sm='2' xs='2'>
                            <Button
                                color='primary'
                                onClick={() => this.searchLocations()}
                            >
                                Search
                            </Button>
                        </Col>
                        <Col xl='1' lg='1' md='2' sm='2' xs='2'>
                            <Button
                                color='primary'
                                onClick={() => this.props.history.push('/admin/locations/create')}
                            >
                                + Add
                            </Button>
                        </Col>
                    </Row>
                    <Row className='row-20'>
                        <Col>
                            <Table bordered hover>
                                <thead>
                                    <tr>
                                        <th>
                                            Id               
                                            <FaSort
                                                color={this.state.sort.column === 'id' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('id')}
                                                className='sort-button'
                                            />                             
                                        </th>
                                        <th>
                                            Name
                                            <FaSort
                                                color={this.state.sort.column === 'name' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('name')}
                                                className='sort-button'
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.locations.map((location,  i) => {
                                        return (
                                            <tr
                                                key={location.id}
                                                onClick={() => this.handleRowClick(location.id)}
                                            >
                                                <td>{location.id}</td>
                                                <td>{location.label}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>                    
                </Container>
            </div>
        )
    }

    async getLocations() {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/Locations/GetDropdown`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`}
        });
        const data = await response.json();
        this.setState({ locations: data });
    }

    async searchLocations() {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/Locations/SearchLocations?query=${this.state.search}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`}
        });
        const data = await response.json();
        this.setState({ clients: data });
    }

    handleRowClick(locationId) {
        this.props.history.push({
            pathname: '/admin/locations/details',
            state: { locationId: locationId, existingLocation: true }
        });
    }

    setWindowDimensions = () => {
        this.setState({
            height: window.innerHeight,
            width: window.innerWidth
        });
    };

    // table sort

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.locations;
        data.sort((a, b) => a[column].toString().localeCompare(b[column].toString()))
        if(direction === 'desc') {
            data.reverse();
        }
        this.setState({
            locations : data,
            sort: {
                column,
                direction
            }
        })
    };
}